import React from 'react'
import Contract from './Contract';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GCard from '../../Layout/GCard'
import { FaPlusSquare, FaUpload, FaSearch, FaArrowAltCircleDown, FaTimesCircle } from 'react-icons/fa'
import Dropzone from 'react-dropzone'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AxiosUpload from '../../../utils/AxiosUpload'
import ConfirmDialog from '../../Common/ConfirmDialog/ConfirmDialog';
import Axios from '../../../utils/Axios';
import FilePreview from '../../Common/FilePreview';
import Config from '../../../Config'
import { saveAs } from 'file-saver';
import FileDownload from 'js-file-download'

const config = Config[process.env.NODE_ENV]

const styles = theme => ({
    root: {},
    paper: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit,
        height: '23vh'
    },
    header: {
        padding: '5px', margin: "-8px", backgroundColor: 'grey'
    },
    dragActive: {
        border: '1px dotted grey',
        height: '50px',
        backgroundColor: "navy", color: 'white',
        marginBottom: '5px',
        textAlign: 'center',
        borderRadius: '10px 10px 10px 10px'
    },
    dragUndefined: {
        border: '1px solid lightgrey',
        height: '50px',
        backgroundColor: "#e0e0e0",
        textAlign: 'center',
        marginBottom: '5px',
        borderRadius: '10px 10px 10px 10px'
    }
})

class ContractAtasamente extends React.Component {

    state = {
        pendingUploads: [],
        tabIndex: 0,
        uploadSuccesful: false,
        focus: null,
        removeConfirmationModal: false
    }

    addAttachment = () => { }

    onDrop = (acceptedFiles, rejectedFiles) => {
        // Do something with files
        // console.log('accepted files:', acceptedFiles);
        // console.log('rejected files:', rejectedFiles);
        console.log(acceptedFiles)
        let pendingUploads = this.state.pendingUploads
        pendingUploads.push(...acceptedFiles)
        this.setState({
            pendingUploads: pendingUploads,
            tabIndex: 1
        })
    }

    removePendingFile = index => {
        let pendingUploads = this.state.pendingUploads
        pendingUploads.splice(index, 1)
        this.setState({ pendingUploads: pendingUploads })

    }

    handleTab = (ev, value) => {
        // console.log("val", value)
        this.setState({ tabIndex: value });
    };

    uploadFilesHandler = () => {
        const files = this.state.pendingUploads
        let promises = files.map( async file => {
            try {
                const formData = new FormData();
                formData.append('file',file )
                formData.append('parent', this.props.id)
              
                return await AxiosUpload({  data: formData })
                
            } catch (err) { console.log(err)}
            finally { }
      })
      let contract = {...this.props};
      Promise.all(promises).then( r => {
        console.log("promises rezolved:", r)
        let uploaded = r.map ( e => e.data.file)
        contract.uploads = [...contract.uploads, ...uploaded]
        this.props.childHandler(contract)
        this.setState({tabIndex: 0, pendingUploads:[]})
        
    })
    }

    removeUploadConfirmation = item => {
        this.setState({
            removeConfirmationModal: true,
            filePreviewModal: false,
            focus: item,
        })
    }

    removeUploadHandler = async ev => {
        let promise = null;
        this.setState({focus: null, removeConfirmationModal: false})
        if ( ev ) {
            try {
                promise = await Axios({
                    method: 'POST',
                    url:'/unlink',
                    data: this.state.focus
                })
            } catch(err) { console.log("file unlink err: ", err)}
            finally {
                let contract = this.props;
                let current = contract.uploads.filter( elem => elem.id === promise.data.file.id )[0]
                contract.uploads.splice(contract.uploads.indexOf(current),1)
                this.props.childHandler(contract)
                
            }
        }
    }

    filePreview = elem => {
        console.log(elem)
        this.setState({
            focus: elem,
            filePreviewModal: true,
        })
    }

    fileDownload = elem => {
        let url = config.API_PATH + '/getFile/' + encodeURI(elem.path)
        Axios({
            method: 'GET', 
            url: url,
            responseType: 'arraybuffer'
        }).then( r =>{ 
            let blob = new Blob([r.data], { type: elem.mimetype })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = elem.originalname
            link.click()
        
        })
    }
          
    render() {

        const { classes } = this.props
        const { tabIndex } = this.state;
        // console.log(this.props)

        const header = (
            <Grid container direction="row" justify="space-between" alignItems="center">
                <div>DOCUMENTE ATAȘATE</div>
                {this.props && this.props.id
                    ? (
                        <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps, isDragActive }) => {
                                // console.log("isDragActive: ", isDragActive)
                                return (
                                    <div
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />

                                        <FaPlusSquare style={{ fontSize: '1.2em' }} />

                                    </div>
                                )
                            }}

                        </Dropzone>
                    )
                    : (
                        <Typography variant="caption" color="secondary"> salvați contractul pentru atasari noi</Typography>
                    )
                }

            </Grid>
        )

        const addNew = (
            <div>
                <Dropzone onDrop={this.onDrop} >
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        // console.log("getRootProps: ", getRootProps)
                        return (
                            <div
                                {...getRootProps()}
                                className={!isDragActive ? classes.dragUndefined : classes.dragActive}
                            >
                                <input {...getInputProps()} />
                                <p style={{ fontWeight: 800 }}>TRAGE FIȘIERE AICI</p>
                            </div>
                        )
                    }}
                </Dropzone>
            </div>
        )

        const listPending = (
            <Paper>
                {this.state.pendingUploads.length > 0
                    ? (
                        <Grid container direction ="row" justify="space-around" alignItems="center" style = {{paddingTop: '10px'}}>
                            <Button variant="contained" color="primary" size="small" onClick = {this.uploadFilesHandler}>
                                <Grid container direction ="column">
                                <div><FaUpload style = {{fontSize: '2em'}} /></div>
                                <small>Încarcă {this.state.pendingUploads.length} 
                                {this.state.pendingUploads.length === 1 ? ' fișier' : ' fișiere'}
                                </small>
                                </Grid>
                            </Button>
                            <Button variant="contained" color="secondary" size="small" onClick={() => this.setState({ pendingUploads: [] })}>
                                <Grid container direction ="column">
                                    <div><FaTimesCircle style = {{fontSize: '2em'}} /></div>
                                    <small>Ștergeți toate</small>
                                </Grid>
                                
                            </Button>

                        </Grid>
                    )
                    : null
                }
                <div style={{ height: '25vh', overflow: 'auto' }}>
                    {this.state.pendingUploads.map((e, i) => (
                        <span key={`pending-${i}`}>
                            <Grid container justify="flex-start" alignItems="center">
                                <FaTimesCircle style={{ fontSize: '1.3em', color: 'red', padding: '5px' }} onClick={() => this.removePendingFile(i)} />
                                <Typography variant="caption">{e.name}</Typography>
                            </Grid>
                        </span>
                    ))}
                </div>
            </Paper>
        )

        const listItems = (

            <div>
                {this.props.uploads.length > 0 
                    ? (
                        <div style={{ height: '35vh', overflow: 'auto' }}>
                            {this.props.uploads.map( (e,i) => (
                                <div key = {`key-${i}`} style ={{margin: '3px', padding: '5px'}}>
                                    <Grid container justify = "space-between">
                                        <Typography variant = "caption" color = "primary"> 
                                            {e.originalname} ({parseFloat(e.size/(1024*1024)).toFixed(2)} MB)
                                        </Typography>
                                        <div>
                                            <FaSearch style={{  color: 'grey', paddingRight: '10px' }} onClick={() => this.filePreview(e)} />
                                            <FaArrowAltCircleDown style={{  color: 'navy', paddingRight: '10px' }} onClick={() => this.fileDownload(e)} />
                                            <FaTimesCircle style = {{color: 'red'}} onClick = { ()=> this.removeUploadConfirmation(e)} />
                                        </div>
                                        
                                    </Grid>
                                    <hr style = {{ margin: 0, padding:0, background: 'lightgrey' }} />
                                    
                                </div>
                            )) }
                        </div>

                    ) : (
                        <div>
                            <Typography variant="h6" style={{ color: 'lightgrey' }} align="center">
                                NU EXISTĂ FIȘIERE ATAȘATE ACESTUI DOCUMENT
                                    {/* <br />
                                    <Button variant="outlined" onClick={() => this.setState({ tabIndex: 1 })}>
                                        Adaugă
                                    </Button> */}
                            </Typography>
                        </div>

                    )
                }
            </div>

        )

        return (
            <GCard header={header}>
                <Tabs
                    value={tabIndex}
                    onChange={this.handleTab}
                    indicatorColor="primary"
                    textColor="primary"
                    style = {{width: '100%'}}
                >

                    <Tab label={`LISTA (${this.props.uploads.length})`} />
                    <Tab label="ADAUGARE" />
                </Tabs>

                {tabIndex === 0 && (
                    <div>
                        <div>
                            {listItems}
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            {addNew}                           
                        </div>
                        </div>
                )}
                {tabIndex === 1 && !this.state.uploadSuccesful && (
                    // <GCard headerVisible="no">
                    <div>
                    <div style = {{cursor:'pointer'}}>
                        {addNew}
                    </div>
                    <div style={{ marginTop: '3vh' }}>
                        {listPending}
                    </div>
                 {/* </GCard> */}
                    </div>
                )}

                <ConfirmDialog 
                    open = { this.state.removeConfirmationModal}
                    onClose = { this.removeUploadHandler }
                    title =  {`Ștergere fișier ${this.state.focus ?this.state.focus.originalname : null }`}
                >
                    
                </ConfirmDialog>

                <FilePreview
                    open = { this.state.filePreviewModal }
                    data = { this.state.focus }
                />
            </GCard>
        )
    }
}

export default withStyles(styles)(ContractAtasamente)
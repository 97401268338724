import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const styles = theme => {

    return ({
        root: {
            flexGrow: 1,
            // border: '1px solid red'
        },
        paper: {
            marginBottom: theme.spacing.unit,
            padding: theme.spacing.unit * 2,
            // flexGrow: 1

        },
        dense: {
            marginTop: 16,
        },
        input: {
            fontSize: '0.75em',
            background: 'white',
            color: "navy",
            fontWeight: 800,
            '&::placeholder': {
                color: 'blue',

            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '8px',
                color: "orange",
                "&$focused": {
                    color: "red"
                }
            },
            focused: {
                "&$focused": {
                    color: "yellow"
                }
            }
        }
    })
}

class FiltruListe extends React.Component {

    render() {
        const { classes } = this.props;
        console.log("filtru props:", this.props.start, this.props.end)
        return (
            <div className={classes.root} >

                <Paper className={classes.paper}>
                    <Grid container justify="flex-start" alignItems="center">
                        <Typography variant="subtitle1">
                            Filtrează înregistrările
                            </Typography>
                        <div>&nbsp;</div>
                        <Grid item xs={12}>
                            <TextField
                                defaultValue={this.props.start}
                                // value={this.props.start}
                                onChange={this.props.inputHandler('start')}
                                className={classes.textField}
                                type="date"
                                InputProps={{ classes: { input: classes.input } }}
                                fullWidth={true}
                                variant="outlined"
                                margin="normal"
                                label="de la data"
                                dense="true"
                            />
                        </Grid>
                        <div>&nbsp;</div>
                        <Grid item xs={12}>
                            <TextField
                                defaultValue={this.props.end}
                                onChange={this.props.inputHandler('end')}
                                className={classes.textField}
                                type="date"
                                InputProps={{ classes: { input: classes.input } }}
                                fullWidth={true}
                                variant="outlined"
                                margin="normal"
                                label="la data"
                                dense="true"
                            />

                        </Grid>
                    </Grid>

                </Paper>

            </div>

        )
    }
}

export default withStyles(styles)(FiltruListe)
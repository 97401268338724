import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import moment from 'moment'


const styles = theme => ({
    root: {},
    paper: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit * 2
    }
})

class ContractChooser extends React.Component {

    state = {registru: null, dataLoaded: false}

    handleChange = event => {
        // console.log("event:", event)
        let contract = this.props.registru.filter( el => el.id === event.value )
        if ( contract.length > 0 ) {
            this.props.handleContractChosen(contract[0])
        }
    }

    componentDidMount() {
        let reg = []
        this.props.registru.map( e=> {
            if ( !e.parent ) {
                reg.push({ value: e.id, label: "contract "+e.nr_doc+" / "+moment(e.data_doc).format("DD.MM.YYYY") })
            }
        })
        this.setState({registru: reg, dataLoaded: true})
    }

    render() {
        // console.log("chooser", this.props)

        const { classes } = this.props
        // console.log(this.props)
        return (
            <Paper className = {classes.paper}>
                Alegeți contractul părinte
                {this.state.dataLoaded 
                    ? <Select 
                        options = {this.state.registru} 
                        onChange = { this.handleChange }
                      
                    />
                    : null
                }
                
            </Paper>
        )
    }
}
export default withStyles(styles)(ContractChooser)
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Axios from '../../utils/Axios';
import CustomToolbar from '../Layout/CustomToolbar';
import { FaTimes, FaCheck } from 'react-icons/fa'
import Hidden from '@material-ui/core/Hidden'

const styles = theme => (
    {
        root: {},
        paper: {
            marginBottom: theme.spacing.unit * 1,
            padding: theme.spacing.unit * 0.5,
            background: 'lightgrey',
        },
        grow: { flexGrow: 1 },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
            width: 120,
        },
        input: { fontSize: '0.8em' },
        menu: { width: 200, fontSize: '0.8px' },
        dense: { marginTop: 19 },
        dialog: { height: '100vh' },
        caption: { paddingTop: '0' }

    }
)

const DocTypes = [
    { value: 'all', label: 'toate documentele' },
    { value: 'CONTRACT', label: 'contractele' },
    { value: 'ACT ADIȚIONAL', label: 'actele adiționale' },
    { value: 'REZILIERE', label: 'rezilierile' },
]

class Filter extends React.Component {

    state = {
        registru: this.props.registru,
        dialogOpen: false,
        found: [],
        lookup: '',
        contract: {
            tip_doc: 'all',
            contact: '',
            start: '2000-01-01',
            end: moment(new Date()).format('YYYY-MM-DD'),
        },
        anexa: {
            nr_act: '',
            tarlaua: '',
            parcela: '',
            ha: '',
            bloc_fizic: '',
            categoria: ''
        },
        docTypes: DocTypes,
        contractFilter: false,
        anexaFilter: false,
        selection: [],
        visible: true
    }

    clearContact = () => {
        let contract = this.state.contract;
        contract.contact = ''
        return this.setState({ contract: contract, dialogOpen: false, found: [], lookup: '' })
    }

    whitespace = (item) => item.replace(/(^\s+|\s+$)/g, '');

    filterContractHandler = async (name, event) => {
        // console.log("filterContractHandler:",name,event.target.value)

        let contract = this.state.contract;

        contract[name] = event.target.value;
        this.setState({ contract: contract, contractFilter: false })

    }

    filterAnexeHandler = name => event => {
        let anexa = this.state.anexa;
        anexa[name] = event.target.value
        this.setState({ anexa: anexa, anexaFilter: false })
        anexa = this.state.anexa;
    }
    contactSelectorHandler = () => {
        // console.log("contactSelectorHandler")
        this.setState({ dialogOpen: true })
    }
    handleDialogClose = () => {
        this.setState({ dialogOpen: false })
    }
    removeFilterHandler = name => event => {
        this.setState({ [`${name}Filter`]: false })
    }
    contactLookupHandler = async (ev) => {
        let result = null;
        this.setState({ lookup: ev.target.value })
        try {
            result = await Axios({
                method: 'POST',
                url: '/contacte',
                data: { term: ev.target.value }
            })
        } catch (err) { console.log("lookup err:", err) }
        finally {
            this.setState({ found: result.data.result })
        }
    }
    chooseContactHandler = name => ev => {
        // console.log("event:", name)
        let contract = this.state.contract;
        contract.contact = name;
        this.setState({ dialogOpen: false, contract: contract, contractFilter: false })

    }
    resetFilter = async name => {
        let result = null
        // console.log("name:", name)
        try {
            if (name === 'contract') {
                this.clearContact();
                let contract = this.state.contract;
                contract = { tip_doc: 'all', contact: '', start: '2000-01-01', end: moment(new Date()).format('YYYY-MM-DD') }
                result = await this.setState({ contract: contract, contractFilter: false, found: [], lookup: '' })
            }
            if (name === 'anexa') {
                let anexa = { nr_act: '', tarlaua: '', parcela: '', ha: '', bloc_fizic: '', categoria: '' }
                let contract = this.state.contract;

                result = await this.setState({ anexa: anexa, anexaFilter: false })
            }
        } catch (err) { console.log("setState reset err", err) }
        finally {
            let q = this.queryBuilder()
            this.props.history.push(q)
        }

    }
    applyFilter = name => {
        // console.log("apply:",name)

        let filters = { contractFilter: name === 'contract' ? true : false, anexaFilter: name === 'anexa' ? true : false }
        if (name === 'contract') {
            this.resetFilter('anexa')
        } else {
            this.resetFilter('contract')
        }
        this.setState(filters)

        // <Route path = "/registru/:tip_doc/:nume?/:start?/:end?/:nr_act?/:tarlaua?/:parcela?/:ha?/:bloc_fizic?/:categoria?" render = { () => <RegFiltered {...this.props} />} />
        let q = this.queryBuilder()
        this.props.history.push(q)

    }

    queryBuilder = () => {
        // console.log("query builder sais the state is:\n", this.state)
        let d = { ...this.state.contract, ...this.state.anexa }
        let q = '/registru/';
        q += d.tip_doc === 'anexa' ? 'anexa' : d.tip_doc;
        q += d.contact ? '/' + encodeURI(d.contact) : '/' + null;
        q += d.start ? '/' + encodeURI(d.start) : '/' + null;
        q += d.end ? '/' + encodeURI(d.end) : '/' + null;
        q += d.nr_act ? '/' + encodeURI(d.nr_act) : '/' + null;
        q += d.tarlaua ? '/' + encodeURI(d.tarlaua) : '/' + null;
        q += d.parcela ? '/' + encodeURI(d.parcela) : '/' + null;
        q += d.ha ? '/' + encodeURI(d.ha) : '/' + null;
        q += d.bloc_fizic ? '/' + encodeURI(d.bloc_fizic) : '/' + null;
        q += d.categoria ? '/' + encodeURI(d.categoria) : '/' + null;
        return q
    }

    render() {

        const { classes } = this.props;

        return (
            <div>

                <div>
                    <Dialog className={classes.dialog}
                        onClose={this.handleDialogClose}
                        open={this.state.dialogOpen}
                        fullWidth={true}

                        onBackdropClick={() => { this.setState({ dialogOpen: false }) }}>

                        <DialogTitle>
                            Filtrează titularul
                            <br />
                            <TextField value={this.state.lookup}
                                autoFocus={true}
                                variant="outlined"
                                onChange={ev => setTimeout(this.contactLookupHandler(ev), 300)}
                                style={{ width: '85%' }}
                                placeholder="caută titular" />
                            <Button variant="text" onClick={() => this.clearContact()}>
                                <FaTimes style={{ fontSize: '2em', color: 'red' }} />
                            </Button>
                        </DialogTitle>
                        <DialogContent style={{ height: '50vh' }}>
                            <ul style={{ listStyleType: 'none' }}>
                                {this.state.found
                                    ? this.state.found.map((e, i) => (<li key={i}> <Button variant="text" onClick={this.chooseContactHandler(e.nume)}>{e.nume} </Button></li>))
                                    : null
                                }
                            </ul>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDialogClose} color="primary"> ÎNCHIDE</Button>
                        </DialogActions>
                        {/* </Paper> */}
                    </Dialog>

                    <CustomToolbar color="default">
                        {/* <Grid container direction = "row" justify = "space-between" alignItems="baseline"> */}
                        {/* <Grid item> */}
                        <Typography variant="caption" className={classes.caption}>
                            Arată
                            </Typography>
                        {/* </Grid> */}
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <TextField
                                select
                                style={{ minWidth: '100%' }}
                                size="small"
                                value={this.state.contract.tip_doc}

                                onChange={event => {
                                    this.filterContractHandler('tip_doc', event);
                                    // this.props.filterHandler(this.state.selection)
                                }}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    }
                                }}
                                className={classes.textField}

                            >
                                {DocTypes.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Typography variant="caption" className={classes.caption}>
                            conținând titularul &nbsp;
                        </Typography>
                        <Grid item xs={12} sm={2} md={3} lg={2}>
                            <TextField
                                className={classes.textField}
                                style={{ minWidth: '100%' }}
                                fullWidth={true}
                                InputProps={{ classes: { input: classes.input } }}
                                value={this.state.contract.contact}
                                onClick={this.contactSelectorHandler}
                                placeholder="click pentru a alege titularul..."
                            />
                        </Grid>
                        <Typography variant="caption" className={classes.caption} style={{ marginLeft: '10px' }}>
                            între  &nbsp; &nbsp;
                        </Typography>
                        <Grid item xs={12} sm={2} md={3} lg={2} style={{ display: 'flex' }}>

                            <TextField
                                type="date"
                                size="small"
                                style={{ minWidth: '50%' }}
                                InputProps={{
                                    classes: {
                                        input: classes.input
                                    }
                                }}
                                defaultValue={this.state.contract.start}
                                onChange={event => { this.filterContractHandler('start', event) }}
                            >

                            </TextField>
                            &nbsp;
                        <TextField
                                type="date"
                                // style = {{ width: 150}}
                                size="small"
                                fullWidth={true}
                                InputProps={{
                                    classes: {
                                        input: classes.input
                                    }
                                }}
                                defaultValue={this.state.contract.end}
                                onChange={event => { this.filterContractHandler('end', event) }}
                            ></TextField>
                        </Grid>
                        {/* </Grid> */}

                        {this.state.contractFilter
                            ? <Button variant="text" color="secondary" size="small" onClick={() => this.resetFilter('contract')}>
                                <FaTimes style={{ fontSize: '2em' }}></FaTimes>
                                <small>Șterge filtrul</small>
                            </Button>
                            : <Button variant="text" style={{ color: 'green' }} color="primary" size="small" onClick={() => this.applyFilter('contract')}>
                                <FaCheck style={{ fontSize: '2em' }}></FaCheck>
                                <small>Aplică filtrul</small>
                            </Button>
                        }

                    </CustomToolbar>

                    <Hidden only="xs">
                        <CustomToolbar color="default">
                            {/* <Grid container direction = "row" justify = "space-between" alignItems="center"> */}
                            <Grid item xs={1}>
                                <Typography variant="caption" className={classes.caption}>
                                    Filtrează anexele
                                </Typography>
                            </Grid>
                            <TextField
                                label="titlu/document"
                                InputProps={{ classes: { input: classes.input } }}
                                // className = {classes.textField}
                                style={{ marginTop: '-12px' }}
                                onChange={this.filterAnexeHandler('nr_act')}
                                // variant = "outlined"
                                size="small"
                                value={this.state.anexa.nr_act}
                            />
                            &nbsp;
                            <TextField
                                label="tarlaua"
                                InputProps={{ classes: { input: classes.input } }}
                                className={classes.textField}
                                onChange={this.filterAnexeHandler('tarlaua')}
                                style={{ marginTop: '-12px' }}
                                // margin="dense"                                    
                                value={this.state.anexa.tarlaua}
                            />
                            &nbsp;
                            <TextField
                                label="parcela"
                                InputProps={{ classes: { input: classes.input } }}
                                className={classes.textField}
                                onChange={this.filterAnexeHandler('parcela')}
                                style={{ marginTop: '-12px' }}
                                // margin="dense"                                    
                                value={this.state.anexa.parcela}
                            />
                            &nbsp;
                            <TextField
                                label="suprafața (ha)"
                                InputProps={{ classes: { input: classes.input } }}
                                className={classes.textField}
                                onChange={this.filterAnexeHandler('ha')}
                                style={{ marginTop: '-12px' }}
                                // margin="dense"                                    
                                value={this.state.anexa.ha}
                            />
                            <TextField
                                label="bloc fizic"
                                InputProps={{ classes: { input: classes.input } }}
                                className={classes.textField}
                                onChange={this.filterAnexeHandler('bloc_fizic')}
                                style={{ marginTop: '-12px' }}
                                // margin="dense"                                    
                                value={this.state.anexa.bloc_fizic}
                            />
                            &nbsp;
                            <TextField
                                label="categorie"
                                InputProps={{ classes: { input: classes.input } }}
                                className={classes.textField}
                                onChange={this.filterAnexeHandler('categoria')}
                                style={{ marginTop: '-12px' }}
                                // margin="dense"                                    
                                value={this.state.anexa.categoria}
                            />
                            &nbsp;
                        {/* </Grid> */}
                            <Grid item>
                                {this.state.anexaFilter
                                    ? <Button variant="text" color="secondary" size="small" onClick={() => this.resetFilter('anexa')}>
                                        <FaTimes style={{ fontSize: '2em' }}></FaTimes>
                                        <small>Șterge filtrul</small>
                                    </Button>
                                    : <Button variant="text" style={{ color: 'green' }} color="primary" size="small" onClick={() => this.applyFilter('anexa')}>
                                        <FaCheck style={{ fontSize: '2em' }}></FaCheck>
                                        <small>Aplică filtrul</small>
                                    </Button>
                                }
                            </Grid>
                        </CustomToolbar>
                    </Hidden>

                </div>

            </div>

        )
    }

}

export default withRouter(withStyles(styles)(Filter));
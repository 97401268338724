import React from 'react'
import ReactDOM from 'react-dom'
import MainContent from '../Layout/MainContent'
import GCard from '../Layout/GCard'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Axios from '../../utils/Axios'
import withWidth from '@material-ui/core/withWidth'
import Button from '@material-ui/core/Button'
import ReactTableInstance from '../Common/ReactTableInstance'
import FiltruListe from './FiltruListe'
import { FaPrint, FaFileContract, FaTimesCircle } from 'react-icons/fa'
import Print from '@material-ui/icons/Print'
import Docs from './Docs';
import moment from 'moment'
import PrintTemplate from 'react-print'
import GTable from './GTable'



const styles = theme => {
    return ({
        root: {},
        fullHeight: {
            height: '25vh'
        }
    })
}

class Liste extends React.Component {

    state = {
        active: { nume: '' },
        contacte: null,
        registru: null,
        dataLoaded: false,
        perPage: 10,
        start: moment().format('YYYY-MM-DD'),
        end: moment().year() + '-12-31',
        isPrinting: false
    }

    async componentDidMount() {
        let promise = null, registru = null;
        try {
            promise = await Axios({
                method: 'POST',
                url: 'registru',
                data: {}
            })
        } catch (err) { console.error(err) }
        finally {
            registru = promise.data.data.registru.map(item => {
                item.parte1 = item.parte1 ? JSON.parse(item.parte1) : []
                item.parte2 = item.parte2 ? JSON.parse(item.parte2) : []
                item.props = item.props ? JSON.parse(item.props) : {}
                return item
            })
            this.setState({ dataLoaded: true, registru: registru, contacte: promise.data.data.contacte })
        }
    }

    docSwitchHandler = doc => {
        this.setState({ active: doc })
    }

    renderTitle = doc => (
        <div key = "title">
            <Grid container justify="center"><Typography variant="headline"> {doc.name} </Typography></Grid>
            <Grid container justify="center"><Typography variant="subtitle1">
                în perioada {moment(this.state.start).format('DD.MM.YYYY')} - {moment(this.state.end).format('DD.MM.YYYY')}
            </Typography></Grid>
        </div>
    )

    renderContent = activeDoc => {
        console.log("-----------active doc-----------\n", activeDoc,  this.state[`${activeDoc.data}`])
        return (
        <GTable
            columns={[...activeDoc.columns]}
            
            data = { this.state[`${activeDoc.data}`] }
            
        />
    )}

    inputHandler = name => event => {
        // console.log(name, event.target.value, "!!!")
        this.setState({ [name]: event.target.value })

    }

    print = () => {
        this.setState({ isPrinting: true })
        ReactDOM.render(<PrintTemplate></PrintTemplate>, document.getElementById('print-mount'))
        
        setTimeout( () => { window.print()}, 1)
        
        setTimeout( () => this.setState({ isPrinting: false }), 1)

        // console.log("timeout expired")
       
    }

    render() {
        console.log("========LISTE STATE =======\n", this.state)
        const { classes } = this.props

        const listaHeader = (
            <Grid container justify="space-between" alignItems="center">

                <Typography variant="subtitle1"> FILTRE</Typography>

            </Grid>
        )
        const docsListSideBar = (
            <GCard header={(
                <Grid container justify="space-between" alignItems="center">
                    <Typography variant="subtitle1"> LISTE </Typography>
                    <Button
                        onClick={() => this.setState({ active: '' })}
                        size="small"
                        color="inherit"
                    >
                        <FaTimesCircle />
                    </Button>
                </Grid>
            )}

            >
                {this.state.dataLoaded
                    ? (
                        <ul>
                            {[...Docs].map((e, i) => (
                                <li key={`lista-${i}`} onClick={() => this.docSwitchHandler(e)} style={{ paddingBottom: '15px' }}>
                                    {e.name}
                                </li>
                            ))}
                        </ul>
                    )
                    : <div>incarc datele...</div>
                }

            </GCard>
        )

        const filter = (
            <GCard
                headerVisible={this.state.active.name ? '' : 'no'}
                header={listaHeader}>

                {this.state.active.name
                    ? (
                        <FiltruListe inputHandler={this.inputHandler} start={this.state.start} end={this.state.end} />
                    )
                    : null
                }

            </GCard>
        )

        const noReport = (
            <FaFileContract style={{ fontSize: '20em', color: 'lightblue' }} />
        )

        const report = (
            <Grid container direction = "column">
                {this.state.active && this.state.active.name ? this.renderTitle(this.state.active) : null}
                {this.state.active && this.state.active.name ? this.renderContent(this.state.active) : null}
            </Grid>
        )
        return (
            <div>
                <Grid container justify="center" alignItems="flex-start" >
                    { !this.state.isPrinting
                        ? (
                            <Grid item xs = {12}  md={3} lg = {3} >
                                <Grid item xs={12} style = {{paddingRight: '10px'}}>
                                    {docsListSideBar}
                                </Grid>
                                {this.state.active.name
                                    ? (
                                        <Grid item xs={12} style = {{paddingRight: '10px', paddingTop: '20px'}}>
                                            {filter}
                                        </Grid>
                                    )
                                    : null
                                }
                             </Grid>
                        ) : null
                    }

                    <Grid item xs={12} md = {9} lg = {9} >
                        {this.state.active.name
                            ? (
                                <Grid container >
                                    <Grid item xs = {12} style = {{textAlign: 'right'}} id="react-no-print">
                                        <Button
                                            size="small"
                                            color="inherit"
                                            onClick={this.print}
                                        >
                                            <FaPrint />
                                            &nbsp; Tipărire
                                        </Button>
                                    </Grid>
                                    <Grid item xs = {12}>
                                        {report}
                                    </Grid>
                                </Grid>
                            )
                            : noReport
                        }
                    </Grid>

                <div id="print-mount"></div>
            </Grid>
            
            </div >
        )

    }
}

export default withStyles(styles)(withWidth()(Liste))
import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import ContractDateGenerale from './ContractDateGenerale';
import ContractAnexe from './ContractAnexe';
import ContractIstoric from './ContractIstoric';
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CustomToolbar from '../../Layout/CustomToolbar';
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import Interfaces from '../../../utils/interfaces';
import Axios from '../../../utils/Axios'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ConfirmDialog from '../../Common/ConfirmDialog/ConfirmDialog'
import ContractParti from './ContractParti';
import ContractAtasamente from './ContractAtasamente'
import Confirmation from '../../Common/Confirmation'

const styles = theme => {
    return ({
        root: {},
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
            width: 120,
        },
        input: { 
            fontSize: '0.8em',
            color: "navy",
            fontWeight: 800,
            '&::placeholder': {
                color: 'blue'
              }  
        },

        paper: {
            margin: theme.spacing.unit,
            padding: theme.spacing.unit,
            minHeight: '47vh'
        },

    })
}

class Contract extends React.Component {

    state = { 
        dataLoaded: false, 
        contract: null,
        registru: null, 
        childHandler: this.childHandler,
        childAddMenuAnchor: null,
        contacte: this.props.contacte.map( e => { return {value: e, label: e.nume }}),
        removePersonConfirmModal : false,
        removePersonData: null, 
        confirmation: false
   }

    componentDidMount() {
        let item = null;
        if (this.props.match.params.id === "nou") {
            item = Interfaces.contract
            item.nr_doc = parseInt(this.props.maxNumber)+1
        } else {
            item = this.props.registru.filter(item => item.id === parseInt(this.props.match.params.id))
        }
        
        if ( item.length > 0 ) { item = item[0]}
        this.setState({
            contract: item, 
            dataLoaded: true,
            registru: this.props.registru,
            childHandler: this.childHandler,
         })
    }

    // componentWillReceiveProps(newProps) {
       
    //     if ( this.state.contract !== newProps.contract ) {
    //         this.setState({contract: newProps.contract})
    //     }
    // }

    childHandler = ev => {
        this.setState({contract: ev})
    }

    addNewParteHandler = (type) => {
        // console.error("add parte!")
        
        let contract = this.state.contract;
       
            contract[type].push({titular: {...Interfaces.contact}, mandatar: {...Interfaces.contact}})
       
        this.setState({contract: contract})
    }

    addPersonHandler =  (data) => {
       
            this.setState({ contacte: data })
        
    }

    createOrUpdateContract = async item => {
        let result = null
        try {
            result = await Axios({
                method: item.id ? 'PATCH': 'PUT',
                url: item.id ? '/update' : '/create',
                data: {table: 'registru', data: item}
            })
        } catch(err) { console.log("contract create/update err: ", err)}
            finally {
                if ( !item.id ) {
                    item.id = result.data.id
                }
                return item
            }        
    }

    createOrUpdateAnexa =  async (items, parentId) => {
        parentId = parseInt(parentId);
        return await Promise.all(items.map(async item => {
            item.idreg = parentId;
            return await Axios({
                method: item.id ? 'PATCH': 'PUT',
                url: item.id ? '/update' : '/create',
                data: {table: 'anexe', data: item}
            });
        }));

    }

    submitHandler = async () => {
        let resultC = null, resultA = null
        let contract = {...this.state.contract}
        let anexe = [...this.state.contract.anexes]
       
        anexe.map( e => e.ha = e.ha ? e.ha : 0)
        //preparing object for post
        contract.parte1 = JSON.stringify(contract.parte1_parsed)
        contract.parte2 = JSON.stringify(contract.parte2_parsed)
        contract.props = JSON.stringify(contract.props)

        delete(contract.parte1_parsed)
        delete(contract.parte2_parsed)
        delete(contract.anexes)

        // delete(contract.anexes)
        console.log(contract)
        console.log("anexe: ",anexe)
        try {
            resultC = await this.createOrUpdateContract(contract)
            console.log("resultC: ", resultC)
                resultA = await this.createOrUpdateAnexa(anexe, resultC.id)
            
        } catch(err) { console.log("contract sql err: ", err)}
        finally {
            // console.log("done sql queries !",  resultC)
            
            resultA.map(item => console.log("anexemapped:", item.data.data))
            let top = document.getElementById('top')
            top.scrollIntoView({behaviour:'smooth'})
            this.setState({confirmation: true})
        }
    }

    // kind of validator born out of my deepest madness

    disabledHandler = () => {
        if ( this.state.contract) {
            if ( this.state.contract.nr_doc === '') return true
            if ( !moment(this.state.contract.data_doc).isValid() ) return true
            if ( !moment(this.state.contract.start).isValid() ) return true
            if ( !moment(this.state.contract.end).isValid() ) return true
            if ( this.state.contract.parte1_parsed.length  ===  0 ) return true
            if ( this.state.contract.parte2_parsed.length  ===  0 ) return true
        } else {
            return false
        }
        
    }

    render() {
        // console.log("state:",this.state)
        const { classes } = this.props;
       
        return (
            <div >
                
                <CustomToolbar color = "default" position = "fixed">
                    
                    <Grid item>
                        <Grid container direction = "row" justify="flex-start" alignItems = "center">
                            <Fab size = "small" onClick = { ()=> this.props.history.push('/registru/all')}> <ArrowBackIcon/></Fab>
                            &nbsp; &nbsp; &nbsp;
                            <Hidden only="xs">
                                <Typography variant = "h6">
                                    CONTRACT {this.state.dataLoaded ? this.state.contract.nr_doc + ' / ' + moment(this.state.contract.data_doc).format('DD.MM.YYYY') : null }
                                </Typography>
                            </Hidden>
                            <Hidden only={['sm', 'md', 'lg']}>
                                <Typography variant = "caption">
                                Contract {this.state.dataLoaded ? this.state.contract.nr_doc + ' / ' + moment(this.state.contract.data_doc).format('DD.MM.YYYY') : null }
                                </Typography>
                            </Hidden>
                            <div>&nbsp;</div>
                        </Grid>
                     </Grid>
                    <div>
                        {/* adauga act aditional sau reziliere */}
                        { this.state.contract && this.state.contract.id 
                            ? (
                            <span>
                                <Button
                                    size = "small"
                                    variant = "text"
                                    aria-owns = { this.state.childAddMenuAnchor ? 'addition-menu' : undefined }
                                    aria-haspopup = "true"
                                    onClick = { ev => this.setState( {childAddMenuAnchor : ev.currentTarget } ) }
                                    color = "default"
                                > 
                                <AddIcon/>
                                </Button>
                                <Menu
                                    id="addition-menu"
                                    anchorEl={this.state.childAddMenuAnchor}
                                    open={Boolean(this.state.childAddMenuAnchor)}
                                    onClose={()=> this.setState({childAddMenuAnchor: undefined }) }
                                    style = {{zIndex: 99999}}
                                    >
                                    <MenuItem onClick = {()=> this.props.history.push({
                                        pathname: '/act-aditional/nou/'+ this.state.contract.id,
                                        parentId: this.state.contract.id
                                    })}> 
                                        <AddIcon/>&nbsp;ADAUGĂ ACT ADIȚIONAL
                                    </MenuItem>
                                    <MenuItem onClick = {()=> this.props.history.push({
                                        pathname: '/reziliere/nou/'+ this.state.contract.id,
                                        data: this.state.contract.id
                                    })}> > 
                                        <AddIcon/>&nbsp;ADAUGĂ REZILIERE CONTRACT
                                    </MenuItem>
                                  
                                </Menu>
                                &nbsp;
                            </span> )
                            : null
                        }
                    <Fab 
                        size = "small"
                        onClick = { this.submitHandler }
                        disabled = { this.disabledHandler() }
                        >
                        <CheckIcon/>
                    </Fab>
                    </div>
                </CustomToolbar>
                <div id = "top"></div>
                {this.state.dataLoaded 
                ? (
                <Grid container direction = "row" justify = "space-evenly" alignItems = "stretch"  >

                            <Grid item xs = {12} sm = {4} lg = {4}>
                                    <ContractDateGenerale 
                                        {...this.state}
                                    />
 
                            </Grid>

                            <Grid item xs = {12} sm = {5} lg = {5} >

                                                <ContractParti
                                                    dataLoaded  =       { this.state.dataLoaded }
                                                    contract    =       { this.state.contract }
                                                    contacte    =       { this.state.contacte }
                                                    childHandler =      { this.childHandler }
                                                    addPersonHandler =  { this.addPersonHandler }
                                                   
                                                />

                            </Grid>
                            
                            <Grid item xs = {12} sm = {3} lg = {3}>
                                <ContractAtasamente {...this.state.contract} childHandler = {this.childHandler} />
                            </Grid>

                    <Grid item xs = {12} sm = {8} lg = {8} >
                        <ContractAnexe 
                            {...this.state} 
                            
                        />
                    </Grid>
                    <Grid item xs = {12} sm = {4} lg = {4} >
                        <ContractIstoric {...{...this.props.match, registru: this.props.registru, item: this.state.contract}} />
                        
                    </Grid>

                    <ConfirmDialog 
                        open = {this.state.removePersonConfirmModal} 
                        onClose = {this.removePerson } >
                        
                        Ștergerea persoanei este definitivă!
                    </ConfirmDialog>
                    <Confirmation open = {this.state.confirmation} text = "ACTUL ADIȚIONAL A FOST ÎNREGISTRAT!"></Confirmation>
                </Grid>
                ):null }

            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Contract));
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


class ConfirmDialog extends React.Component {

  render() {
    // console.log(this.props)
    return (
      <div>
      {this.props && this.props.open
      ?
        <Dialog
          open={this.props.open}
          onClose={()=>{ this.props.onClose(false)}}
        
          aria-labelledby="alert-dialog"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog">{this.props.title ? this.props.title : 'ATENȚIE!'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.children ? this.props.children : 'ACESTĂ ACȚIUNE ESTE IREVERSIBILĂ! SUNTEȚI SIGUR?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.props.onClose(true)}>
                {this.props.accept ? this.props.accept : 'DA'}
            </Button>
            <Button onClick={()=>this.props.onClose(false)}>
            {this.props.reject ? this.props.reject : 'NU'}
            </Button>
          
          </DialogActions>
        </Dialog>
        : null
      }

      </div>
    );
  }
}

export default ConfirmDialog;
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
// import ActForm from './ActForm'
import Button from '@material-ui/core/Button'
import GCard from '../../Layout/GCard'
const styles = theme => ({
    root: { },
})

const SumarContract = (props) => {

    const { classes } = props

    if (props.istoric && props.istoric.length > 0 ) {
        props.istoric.map( e => {
            let link = e.tip_doc === 'ACT ADIȚIONAL' ? 'act-aditional' : e.tip_doc.toLowerCase()
            e.link = `/${link}/${e.id}`
            console.log("e-link", e.link)
        })
    }
    console.log("simar props: ", props)
    return (
            // <div className = {classes.root}>
            <GCard header = "SUMAR CONTRACT" className = {classes.root}>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                    <Grid item xs={6}>
                        <Typography variant="body2"> <strong>   SUMAR CONTRACT </strong> </Typography>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <Button size = "large" variant = "outlined" color = "primary" onClick = {()=>props.history.push(`/contract/${props.contract.id}`)}>
                                Nr. {props.contract.nr_doc} / {moment(props.contract.data_doc).format('DD.MM.YYYY')}
                                </Button>
                                <br/>
                                <strong>    Arendator(i): </strong>
                                {props.contract.parte1_parsed.map((e, i) => (
                                    <span key={`p1-${i}`}> {e.titular.nume} {props.parte1_parsed.length === i - 1 ? ',' : ''}  </span>
                                ))}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <strong>    Arendaș(i): </strong>
                                {props.contract.parte2_parsed.map((e, i) => (
                                    <span key={`p1-${i}`}> {e.titular.nume} {props.parte1_parsed.length === i - 1 ? ',' : ''}  </span>
                                ))}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <strong> Data încheiere: </strong>
                                {
                                    props.contract.data_doc
                                        ? moment(props.contract.data_doc).format('DD.MM.YYYY')
                                        : null
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <strong> Dată început: </strong>
                                {props.contract.start
                                    ? moment(props.contract.start).format('DD.MM.YYYY')
                                    : null
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <strong> Data încetare: </strong>
                                {props.contract.end
                                    ? moment(props.contract.end).format('DD.MM.YYYY')
                                    : null
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
                </GCard>
            
    )
}

export default withRouter(withStyles(styles)(SumarContract))
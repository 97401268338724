import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button'
import ContactSelect from './ContactSelect';
import ContactModal from '../../Persoane/ContactModal'
import {  FaPlusSquare } from 'react-icons/fa'
import Interfaces from '../../../utils/interfaces';
import GCard from '../../Layout/GCard'

const styles = theme => ({
    root: {  },
    paper: {
        marginBottom: theme.spacing.unit,
        padding: theme.spacing.unit,
        backgroundColor: '#fafafa'
        // width: '100%'
    },
    item: {
        margin: theme.spacing.unit * 0.1,
        padding: theme.spacing.unit,
    },
    textField2: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 230,
    },
    container: {
        marginLeft: theme.spacing.unit*0.5,
        marginRight: theme.spacing.unit*0.5,
        marginBottom: -theme.spacing.unit,
        marginTop: theme.spacing.unit,
        paddingLeft: theme.spacing.unit*0.3,
        paddingRight: theme.spacing.unit*0.3,
        paddingBottom: theme.spacing.unit,
        height: '97%',
        // background: theme.palette.grey[50],
        color: theme.palette.primary.light,
        borderRadius: '10px'
    },

})

class ContractParti extends React.Component {

    // props
        // contract
        // contacte
    //methods
        // addParte(args: parte1_parsed || parte2_parsed) => contractHandler(contract)
        // removeParte ( args: parte1/2, index) => contractHander(contract)
        // changeContact (args: tip parte(parte1_parsed/parte2_parsed), index, tip persoana(titular/mandatar)) => contractHander(contract)
        // addContact (args: parteType: parte1/2, newItem ) => contractHander(contract) && contacteHandler(contacte)
        // removeContact (args: parteType: parte1/2, index, personType:titular/mandatar) => contractHandler(contract) && contacteHandler(contacte)

    state = {
        open: false, 
        dataLoaded: false, 
        contract: null,
        contacte: null,
        parti: ['parte1_parsed', 'parte2_parsed'],
        focus: null
    }

    componentWillMount() {
            // console.error('wiil mount')
            this.setState({
                dataLoaded: this.props.dataLoaded,
                contract: this.props.contract,
                contacte: this.props.contacte
            })
    }

    componentWillReceiveProps(props) {
        // console.error("new props received!")
        this.setState({
            dataLoaded: props.dataLoaded,
            contract: props.contract,
            contacte: props.contacte
        })
    }
 
    addNewPersonHandler = (parte) => {
       let contract = this.state.contract
       contract[parte].push( {titular: {...Interfaces.contact}, madatar: {...Interfaces.contact} } ) 
       this.setState( {contract: contract },
            this.props.childHandler( this.state.contract )
        )
    }

    changeHandler = (parte, i, tipPers, event ) => {
        let contract = this.state.contract
        contract[parte][i][tipPers] = event.value
        this.setState( {contract: contract },
            this.props.childHandler( this.state.contract)
        )
    } 

    addModalOpenHandler = (action, parte, i, tipPers, ev) => {
       
        this.setState( {focus: {
            action: action,
            parte: parte,
            i: i, 
            tipPers: tipPers,
            item: ev
        }, open: true})
    } 

    addOrEditContact = item => {
        let contract    = this.state.contract
        let contacte    = this.state.contacte
        let parte       = this.state.focus.parte
        let index       = this.state.focus.i
        let tipPers     = this.state.focus.tipPers
        
        if ( this.state.focus.action === 'add' ) {
            contacte.push({label: item.nume, value: item})
                this.props.addPersonHandler(contacte);
        }        
        contract[parte][index][tipPers] = item
        if ( this.state.focus.action = "edit" && item.id ) {
            let contactItem = contacte.filter(elem => elem.id === item.id )
            if ( contactItem.length > 0 ) contactItem[0] = {label: item.nume, value: item}
            console.log("contacte after", contacte)
        }
        this.setState( { open: false },
            this.props.childHandler(contract)    
        )

        this.setState({open:false})
    } 

    removeParteHandler = (parte, index) => {
        let contract = this.state.contract
        contract[parte].splice(index,1)
        this.props.childHandler(contract)   
    }

    render() {

        const { classes } = this.props

        return (
            // <div  className = {classes.container} style = {{ border: 'none'}}>
            // <Grid container direction="row" justify="space-between" alignItems="flex-start"  className = {classes.container} style = {{border:'none'}}>
            <GCard headerVisible = "yes" header = "PARTILE CONTRACTULUI">
                { this.state.dataLoaded 
                    ? (
                        this.state.parti.map( (parte,iParte) => {
                            
                            let header = (
                                <Grid container direction = "row" justify = "space-between" alignItems = "center">
                                    <div> {parte === 'parte1_parsed' ? 'ARENDATORI': 'ARENDAȘI'}</div>
                                    <div onClick={ev => this.addNewPersonHandler(parte)} >
                                        <FaPlusSquare />
                                    </div>
                                </Grid>
                            )

                            return (
                            <Grid item xs = {12} key = {`parte-${iParte}`} style ={{marginTop:'-10px', marginBottom: '0'}}>
                                <GCard header = {header} >
                                
                                <ContactModal
                                    open=   { this.state.open }
                                    item=   { this.state.focus && this.state.focus.item ? this.state.focus.item : {...Interfaces.contact} }
                                    submit= { ev => this.addOrEditContact(ev) }
                                    close=  { () => this.setState({ open: false }) }
                                />

                                { this.state.contract[parte]
                                    ? (
                                        this.state.contract[parte].map( (e,i) => (
                                            <Paper className = {classes.paper}  key={`p1-${i}`}>
                                            {/* ELIMINA PARTE */}
                                            <Grid container justify="flex-end" alignItems="flex-start">
                                                {/* ADAUGA PARTE CONTRACTUALA */}
                                                <Typography variant="caption"
                                                    onClick={ev => this.removeParteHandler(parte, i)}
                                                    color="secondary"
                                                    size="small">
                                                    [eliminare]
                                                </Typography>    
                                               
                                            </Grid> 
                                                                                       
                                            <Grid container justify="space-between" alignItems="flex-start"  >
                                                {/* titular */}
                                                <Grid item xs={6} style = {{paddingRight:'3px'}}>
                                                    <Typography 
                                                        variant="caption" 
                                                        color="default">  
                                                        titular
                                                    </Typography>
                                                    <ContactSelect
                                                        value={ (this.state.contacte.filter(item =>(e.titular) ? item.value.nume === e.titular.nume: null))[0] }
                                                        contacte={[...this.state.contacte]}
                                                        textFieldProps={{
                                                        label: 'Label',
                                                        InputLabelProps: {
                                                            shrink: true,
                                                            },
                                                        }}
                                                        onChange={ ev => this.changeHandler(parte, i, 'titular', ev ) }
                                                    />   
                                                    <div style = {{ display: 'flex'}}> 
                                                    {/* ADAUGA TITULAR NOU */}
                                                    <Typography 
                                                        variant="caption" 
                                                        color="default"
                                                        style={{ marginBottom: '10px' }} 
                                                        onClick={ () => this.addModalOpenHandler('add',parte, i, 'titular') }
                                                    >  [adaugare]
                                                    </Typography>
                                                    {/* EDITEAZA TITULAR/MANDATAR EXISTENT */}
                                                    <Typography 
                                                        variant="caption" 
                                                        color="default"
                                                        style={{ marginBottom: '10px' }} 
                                                        onClick={ () => this.addModalOpenHandler('edit',parte, i, 'titular', e['titular']) }
                                                    > &nbsp; [modificare]
                                                    </Typography>
                                                   
                                                    </div>   
                                                </Grid>
                                                
                                                {/* mandatar */}
                                                <Grid item xs={6} style = {{paddingLeft:'3px'}}>
                                                <Typography 
                                                        variant="caption" 
                                                        color="default">  
                                                        mandatar
                                                    </Typography>                                                
                                                    <ContactSelect
                                                        value={(this.props.contacte.filter(item => e.mandatar && item.value.nume === e.mandatar.nume ))[0]}
                                                        contacte={[...this.state.contacte]}
                                                        textFieldProps={{
                                                        label: 'Label',
                                                        InputLabelProps: {
                                                            shrink: true,
                                                            },
                                                        }}
                                                        onChange={ ev => this.changeHandler(parte, i, 'mandatar', ev ) }
                                                    />     
                                                    <div style = {{ display: 'flex'}}> 
                                                    {/* ADAUGA TITULAR NOU */}
                                                    <Typography 
                                                        variant="caption" 
                                                        color="default"
                                                        style={{ marginBottom: '10px' }} 
                                                        onClick={ () => this.addModalOpenHandler('add',parte, i, 'mandatar') }
                                                    >  [adaugare]
                                                    </Typography>
                                                    {/* EDITEAZA TITULAR/MANDATAR EXISTENT */}
                                                    <Typography 
                                                        variant="caption" 
                                                        color="default"
                                                        style={{ marginBottom: '10px' }} 
                                                        onClick={ () => this.addModalOpenHandler('edit',parte, i, 'mandatar', e['mandatar']) }
                                                    > &nbsp; [modificare] 
                                                    </Typography>
                                                
                                                    </div>   
                                                </Grid>
                                   
                                            </Grid>
                                            </Paper>
                                           
                                        ))
                                     ) : null
                                }
                                </GCard>
                                <br/>
                            </Grid>
                            
                        )})
                    ) : <div> data not loaded</div>

                }
</GCard>
        
        )
    }
}

export default withStyles(styles)(ContractParti)
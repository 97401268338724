import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import GCard from '../../Layout/GCard'

const styles = theme => {
    return ({
        root: { flexGrow: 1 },
        textField: {
            // marginLeft: theme.spacing.unit,
            // marginRight: theme.spacing.unit,
            
        },
        dense: {
            marginTop: 16,
        },

        input: { 
            fontSize: '0.75em',
            background: 'white',
            color: "navy",
            fontWeight: 800,
            '&::placeholder': {
                color: 'blue',
               
              } ,

        },
        MuiFormLabel: {
            root: {
              fontSize: '8px',
              color: "orange",
              "&$focused": {
                color: "red"
              }
            },
            focused: {
              "&$focused": {
                color: "yellow"
              }
            }
          }

    })
}

const ContractDateGenerale = (props) => {

    const { classes } = props;

    const inputHandler = name => event => {
        console.log("d gen inputHandler", name, event.target.value)
        let contract = props.contract;
        contract[name] = event.target.value
        props.childHandler(contract)
    }

    const obsHandler = event => {
        let contract = props.contract;
        contract['props']['obs'] = event.target.value
        props.childHandler(contract)
    }
    
    return (
        
        // <Grid container direction = "column" justify="center" alignItems = "stretch">
        <GCard header = "DATE GENERALE">
        <div className = { classes.root }>
           
            <Grid container direction = "row" justify="flex-start" alignItems = "flex-start" spacing = {8}>
                <Grid item xs = {6}>
                    <TextField
                        value = {props.contract.nr_doc}
                        onChange = { inputHandler('nr_doc')}
                        className = {classes.textField}
                        style = {{height: '3.2em'}}
                        type = "number"
                        InputProps={{ classes: {  input: classes.input } }}
                        fullWidth = {true}
                        variant = "outlined"
                        margin="normal"
                        label = "nr. contract"
                        dense = "true"                   
                    />
                </Grid>
                <Grid item xs = {6}>
                    <TextField 
                        defaultValue = {props.contract.data_doc}
                        onChange = {inputHandler('data_doc')}
                        className = {classes.textField}
                        type = "date"
                        InputProps={{ classes: {  input: classes.input } }}
                        fullWidth = {true}
                        variant = "outlined"
                        margin="normal"
                            label = "data înregistrării"
                        dense = "true"                     
                    /> 
                </Grid>   
            </Grid>
            <Grid item xs = {12}>
            {/* <Grid container direction = "row" justify="flex-start" alignItems = "center" > */}
                <Typography variant = "caption" color = "default">VALABILITATEA CONTRACTULUI </Typography>
            </Grid>
            <Grid container direction = "row" justify="flex-start" alignItems = "stretch" spacing = {8}>
                <Grid item xs = {6}>
                    <TextField 
                        defaultValue = {props.contract.start}
                        onChange = {inputHandler('start')}
                        className = {classes.textField}
                        type = "date"
                        InputProps={{ classes: {  input: classes.input } }}
                        fullWidth = {true}
                        variant = "outlined"
                        margin="normal"
                        label = "data începerii"
                        dense = "true"                     
                    /> 
                </Grid>
                <Grid item xs = {6}>
                    <TextField 
                        defaultValue = {props.contract.end}
                        onChange = {inputHandler('end')}
                        className = {classes.textField}
                        type = "date"
                        InputProps={{ classes: {  input: classes.input } }}
                        fullWidth = {true}
                        variant = "outlined"
                        margin="normal"
                        label = "data încetării"
                        dense = "true"                     
                    />  
                </Grid>
                <Grid item xs = {12}>                       
                    <TextField 
                        value = {props.contract.pret}
                        onChange = {inputHandler('pret')}
                        className = {classes.textField}
                        InputProps={{ classes: {  input: classes.input } }}
                        fullWidth = {true}
                        variant = "outlined"
                        margin="normal"
                        label = "prețul contractului (frază)"
                        dense = "true"   
                        InputLabelProps= {{FormLabelClasses:{
                            root: classes.MuiFormLabel.root,
                            focused: classes.MuiFormLabel.focus
                        }}}
                    />
                </Grid>
                <Grid item xs = {12}>    
                    <TextField 
                        value = {props.contract.props.obs}
                        onChange = {obsHandler}
                        className = {classes.textField}
                        InputProps={{ classes: {  input: classes.input } }}
                        fullWidth = {true}
                        style = {{background: 'white'}}
                        variant = "outlined"
                        margin="normal"
                        label = "observații"
                        dense = "true"  
                        multiline = {true}                   
                    />
                </Grid>
            </Grid>
         {/* </Grid> */}
        </div>
        </GCard>
    )
}

export default withStyles(styles)(ContractDateGenerale)
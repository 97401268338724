import React from 'react';
import Toolbartop from '../Layout/Toolbartop';
import MainContent from '../Layout/MainContent';
import { Switch, Route } from 'react-router-dom';
import Landing from './Landing';
import Registru from '../Registru/Registru';
import Contract from '../Registru/Forms/Contract'
import ActAditional from '../Registru/Forms/ActAditional'
import Reziliere from '../Registru/Forms/Reziliere'
import Persoane from '../Persoane/Persoane'
import Liste from '../Liste/Liste'

class Main extends React.Component {

    render() {
        return (
            <div>
            <Toolbartop />
            
                <MainContent>
                    <Switch>
                    <Route path = "/" exact render = { () => <Landing {...this.props} />} />
                    <Route path = "/registru/:tip_doc/:nume?/:start?/:end?/:nr_act?/:tarlaua?/:parcela?/:ha?/:bloc_fizic?/:categoria?" render = { () => <Registru {...this.props} />} />
                    <Route path = "/contract/:id" render = { ()=> <Contract  {...this.props} />} />
                    <Route path = "/act-aditional/:id/:parent?" render = { ()=> <ActAditional  {...this.props} />} />
                    <Route path = "/reziliere/:id/:parent?" render = { ()=> <Reziliere  {...this.props} />} />

                    <Route path = "/persoane/all" render = { ()=> <Persoane  {...this.props} />} />

                    <Route path = "/liste/:name?/:start?/:end?/:params?" component = {Liste} />
                    </Switch>
                
                </MainContent>
            </div>
        )
    }
}

export default Main;
import React from 'react';
// import ReactDom from 'react-dom'
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles'
// import MainContent from '../Layout/MainContent'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
// import MaterialTable from 'material-table';
// import Button from '@material-ui/core/Button'
import { FaPencilAlt, FaTimesCircle ,FaPlus } from 'react-icons/fa'
import ContactModal from './ContactModal'
import Fab from '@material-ui/core/Fab'
import Axios from '../../utils/Axios'
import ConfirmDialog from '../Common/ConfirmDialog/ConfirmDialog'
import ReactTableInstance from '../Common/ReactTableInstance';
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Interfaces from '../../utils/interfaces';
import Confirmation from '../Common/Confirmation'

const styles = theme => {
    console.log(theme)
    return {
        root: { flexGrow: 1 },
        paper: {
            margin: theme.spacing.unit
        },
    }
}

class Persoane extends React.Component {

    state = {
        contacte: null,
        dataLoaded: false,
        pageSize: 10,
        open: false,
        item: null,
        confirmationOpen: false,
        anchorEl: null, 
        confirmation: false
    }

    componentDidMount() {
        this.setState({ contacte: this.props.contacte, dataLoaded: this.props.dataLoaded })
    }

    componentWillReceiveProps(props) {
        if (props !== this.props) {
            this.setState({ contacte: this.props.contacte, dataLoaded: this.props.dataLoaded })
        }
    }

    detailsHandler = name => {
        if (name) {
            this.setState({ open: true, item: name })
        } else {
            let item = {...Interfaces.contact}
            this.setState({open: true, item: item})
        }
    }

    close = () => {
        this.setState({ open: false, data: null })
    }

    submit = async item => {
        console.log(item)
        let result = null;
        try {
            if (item.id) {
                result = await Axios({
                    method: 'PATCH',
                    url: '/update',
                    data: {
                        table: 'contacts',
                        data: item
                    }
                })
            } else {
                delete (item.id)
                result = await Axios({
                    method: 'PUT',
                    url: '/create',
                    data: {
                        table: 'contacts',
                        data: item
                    }
                })
            }
        } catch (err) { console.log("create/update contact err: ", err) }
        finally {
            console.log("result", result)
            let contacte = this.state.contacte;
            let matched = contacte.filter(e => e.id === item.id)
            if (matched.length > 0) {
                contacte[contacte.indexOf(matched[0])] = item
            } else {
                item.id = result.data.id
                contacte.push(item)
            }
            this.setState({ contacte: contacte, anchorEl: null, confirmation: true })
        }

        this.close()
    }

    remove = item => {
        if ( !item) { item = this.state.item }
        this.setState({ confirmationOpen: true, item: item })
    }

    removeConfirmed = async ev => {
        let result = null;
        this.setState({ confirmationOpen: false })
        if (ev === true) {
            console.log(this.state.item)

            try {
                result = await Axios({
                    method: 'POST',
                    url: '/remove',
                    data: {
                        table: 'contacts',
                        data: {
                            id: this.state.item.id
                        }

                    }
                })
            } catch (err) { console.log('removal error: ', err) }
            finally {
                if (result.data && !!result.data.ok) {
                    let contacte = this.state.contacte
                    contacte.splice(contacte.indexOf(this.item), 1)
                    this.setState({ contacte: contacte, confirmationOpen: false, open: false, anchorEl: null })
                }
            }

        }

    }

    contextHandler = (ev, item) => {
        console.log(ev, item)
        this.setState({ anchorEl: ev.currentTarget, item: item })
    }

    render() {

        const { classes } = this.props;

        // console.log("persoane props:", this.props)

        const columns = [
            {
                Header: 'NUME',
                accessor: 'nume',
                Cell: row => <Typography variant="caption" color="primary">{row.original.nume}</Typography>,
                width: 200,

            },
            {
                Header: 'CNP/CUI',
                accessor: 'cnp_cui',
                Cell: row => <Typography variant="caption" color="primary">{row.original.cnp_cui}</Typography>,
                width: 150,
            },
            {
                Header: 'Localitate',
                accessor: 'loc',
                Cell: row => <Typography variant="caption" color="primary">{row.original.loc}</Typography>,
                width: 250,
            },
            {
                Header: 'Strada',
                accessor: 'str',
                Cell: row => <Typography variant="caption" color="primary">{row.original.str}</Typography>,
                width: 200,
            },
            {
                Header: 'Nr.',
                accessor: 'nr',
                Cell: row => <Typography variant="caption" color="primary">{row.original.nr}</Typography>,
                // width: 80,                
            },
            {
                Header: 'Bl.',
                accessor: 'bl',
                Cell: row => <Typography variant="caption" color="primary">{row.original.bl}</Typography>,
                // width: 80,                  
            },
            {

                Header: 'Sc',
                accessor: 'sc',
                Cell: row => <Typography variant="caption" color="primary">{row.original.sc}</Typography>,
                // width: 80,                  
            },
            {
                Header: 'Et',
                accessor: 'et',
                Cell: row => <Typography variant="caption" color="primary">{row.original.et}</Typography>,
                // width: 80,                  
            },
            {
                Header: 'Ap',
                accessor: 'ap',
                Cell: row => <Typography variant="caption" color="primary">{row.original.ap}</Typography>,
                // width: 80,                  
            },
            {
                Header: 'Jud',
                accessor: 'jud',
                Cell: row => <Typography variant="caption" color="primary">{row.original.jud}</Typography>,
                width: 200,
            },

        ]

        return (
            <Paper className={classes.paper} >
                <Grid container direction="row" justify="space-between" alignItems="center" className={classes.root}>
                    <Grid item xs={11}>CONTACTE</Grid>
                    <Grid item style={{ textAlign: 'right' }} xs={1}>
                        <Fab
                            onClick={() =>this.detailsHandler()}
                            color="primary"
                            size="small">
                            <FaPlus />
                        </Fab>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            {this.state.dataLoaded
                                ? (

                                    <ReactTableInstance
                                        data={this.state.contacte}
                                        columns={columns}
                                        pageSize={100}
                                        style={{ height: '75vh' }}
                                        clicked={this.detailsHandler}
                                        context={(ev, item) => this.contextHandler(ev, item)}

                                    />

                                )
                                : null
                            }

                        </Paper>
                    </Grid>
                </Grid>

                <ContactModal
                    open={this.state.open}
                    item={this.state.item}
                    contacte={this.state.contacte}
                    close={this.close}
                    submit={this.submit}
                    showRemove={true}
                    remove={this.remove}
                    dataLoaded={this.state.dataLoaded}
                />

                <ConfirmDialog
                    title="ATENȚIE!"
                    accept="DA"
                    reject="NU"
                    open={this.state.confirmationOpen}
                    onClose={this.removeConfirmed}

                />

                <Menu
                    
                    id="context-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)} 
                    onClose={() => this.setState({ anchorEl: undefined })} >
                        <MenuList
                            component = "nav"
                            style = {{border: '1px solid red'}}
                            subheader = {(
                                <Typography variant = "h6" color = "default" style = {{padding: '10px'}}> 
                                    { this.state.item && this.state.item.nume ? this.state.item.nume : null} 
                                </Typography>
                            )}
                            dense = {true}
                            >
                            <MenuItem onClick = { () => {this.setState({open: true})}}>
                                <Typography variant = "body1" color = "primary">
                                    <FaPencilAlt/> &nbsp; EDITEAZĂ
                                </Typography>
                                <Typography variant = "caption" color = "default">
                                    &nbsp; (actualizează datele de contact)
                                </Typography>                                
                            </MenuItem>
                            <MenuItem onClick = { () => {this.remove(this.state.focus)}}>
                                <Typography variant = "body1" color = "secondary">
                                    <FaTimesCircle/> &nbsp; ȘTERGE
                                </Typography>
                                <Typography variant = "caption" color = "default">
                                    &nbsp; (dacă ne este parte în vreun contract)
                                </Typography>
                            </MenuItem>
                        </MenuList>
                </Menu>
                <Confirmation open = {this.state.confirmation} text = "OPERAȚIUNE FINALIZATĂ !"></Confirmation>

            </Paper>
        )
    }
}

export default withRouter(withStyles(styles)(Persoane));
import React from 'react';
import { withRouter } from 'react-router-dom';
// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment'
import GCard from '../../Layout/GCard'
// import Button from '@material-ui/core/Button'
// import dick  from '../../../assets/dick.png';
// import { FaHandPointer } from 'react-icons/fa';

const styles = theme => ({
    root: { flexGrow: 1 },
    container: {
        margin: theme.spacing.unit * 0,
        marginBottom: theme.spacing.unit * 0.3,
        padding: theme.spacing.unit*0.2,
        paddingTop: theme.spacing.unit * 0.2,
        paddingLeft: theme.spacing.unit * 2,
        border:'1px dotted grey',
        borderRadius: '5px',
        // cursor: `url(${FaHandPointer}),auto`,
        cursor:'pointer',
        '&:hover': { background: 'lightgrey',},

    },

})

const ContractIstoric = (props) => {
    // console.log("ISORIC PROIPS:", props)
    const { classes } = props;

    let current = props.params.id
    let registru = props.registru
    let istoric = registru.filter(elem => elem.parent === parseInt(current))

    const linkHandler = item => () => {
        let tip = null;
        switch (item.tip_doc) {
            case 'CONTRACT': tip = "contract"
                break;
            case 'ACT ADIȚIONAL': tip = "act-aditional"
                break;
            case 'REZILIERE': tip = "reziliere"
                break;
            case 'NOTIFICARE': tip = "notificare"
                break;
            default: tip = "contract"
        }
        return props.history.push(`/${tip}/${item.id}/${item.parent}`)
    }

    return (
        // <div className = {classes.paper}> 
        // <Grid container justify = "space-between" alignItems = "center">
        //     <Typography variant = "body2">&nbsp;&nbsp; ISTORIC CONTRACT</Typography>

        // </Grid>
        <GCard header="ISTORIC CONTRACT">
            <div style={{ height: '12vh', overflow: 'auto' }}>
                {istoric.length > 0
                    ? istoric.map((e, i) => (
                        <div key={`ist-${i}`} onClick={linkHandler(e)}>
                            <div className={classes.container}>
                                <Typography variant="caption" style={{ padding: 0, margin:0 }}>
                                    <strong style={{ color: "navy" }}>{e.nr_doc + ' / ' + moment(e.data_doc).format('DD.MM.YYYY')} {e.tip_doc}</strong>

                                </Typography>
                                <Typography variant="caption" color="primary" style={{ padding: 0, marginTop: '-10px', marginBottom: '-10px' }}>
                                    <i><small><div dangerouslySetInnerHTML={{ __html: e.props.obs }}></div></small></i>
                                </Typography>
                                
                            </div>
                        </div>

                    ))
                    : <Typography variant="caption">Nu există documente în istoric</Typography>

                }
            </div>
            {/* </div> */}
        </GCard>
    )
}

export default withRouter(withStyles(styles)(ContractIstoric))
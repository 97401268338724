import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Interfaces from '../../utils/interfaces';
import TextField from '@material-ui/core/TextField'
import { FaTimes, FaCheck, FaTrash } from 'react-icons/fa'

const styles = theme => (
    {
        dialog: { flexGrow: 1 },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
           
        },
        dense: '8',
        input: {
            fontSize: '0.8em',
            color: "navy",
            fontWeight: 800,
            '&::placeholder': {
                color: 'blue'
            }
        },
        paper: {
            margin: theme.spacing.unit * 0.2,
            padding: theme.spacing.unit  ,
            // height: '45vh',
            width: '100%'
        }
    })

    const Adresa = [
        {value: 'nume', label: 'Nume sau denumire',      xs: 12, sm : 12, md: 12, lg: 12 },
        {value: 'cnp_cui', label: 'CNP/CUI/CIF',         xs: 12, sm :6 , md: 6, lg: 6},
        {value: 'reg_com', label: 'Nr.Reg.Com',          xs: 12, sm : 6, md: 6, lg: 6 },
        {value: 'nr', label: 'Nr',                       xs: 4, sm : 2, md: 2, lg: 3},
        {value: 'bl', label: 'Bloc',                     xs: 4, sm : 2, md: 2, lg: 2},
        {value: 'sc', label: 'Scara',                    xs: 4, sm : 2, md: 2, lg: 2},
        {value: 'et', label: 'Etaj',                     xs: 4, sm : 2, md: 2, lg: 2},
        {value: 'ap', label: 'Ap.',                      xs: 4, sm : 2, md: 2, lg: 2},
        {value: 'jud', label: 'Judet',                   xs: 12, sm : 4, md: 4, lg: 4},
        {value: 'loc', label: 'Localitate',              xs: 12, sm : 4, md: 4, lg: 4 },
        {value: 'tara', label: 'Tara',                   xs: 12, sm : 4, md: 4, lg: 4 },

    ]

class ContactModal extends React.Component {

    state = {
        open: false,
        item: null,
        contacte: null,
        dataLoaded: false
    }

    componentWillMount() {
        this.setState({
            open: this.props.open,
            item: this.props.item || Interfaces.contact ,
            contacte: this.props.contacte ,
            dataLoaded: this.props.dataLoaded
        })
    }

    componentWillReceiveProps(props) {
        if (this.props !== props)
           
        this.setState({
            open: props.open, 
            item: {...props.item} || {...Interfaces.contact},
            contacte: props.contacte,   
            dataLoaded: props.dataLoaded
         })
        return true
    }

    inputHandler = name => ev => {
        let item = this.state.item;
        item[name] = ev.target.value;
        this.setState({item: item})
    }
    render() {

        const { classes } = this.props

        // console.log("props:", this.props, '\n state:',this.state)

        return (
            <Dialog className={classes.dialog}
                    onClose={this.close}
                    open={this.state.open}
                    fullWidth={true}
                    maxWidth = "lg"

                    onBackdropClick={() => { this.setState({ open: false }) }}>

                    <DialogTitle>
                        <Grid container direction = "row" justify = "space-between" alignItems = "center">
                            {this.state.item.id 
                                ? <span>Editează persoana: {this.state.item.nume}</span>
                                : <span>Adaugă persoană nouă</span>
                            } 
                            <div>
                                { this.state.item.id && this.props.showRemove 
                                ? <span><FaTrash  onClick = { () => this.props.remove(this.state.item)} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                : null
                                }
                                
                                <FaTimes onClick = {this.props.close}/>
                            </div>
                        </Grid>
                       
                    </DialogTitle>
                    <DialogContent >
                        {/* <Paper className = {classes.paper}> */}
                            <Grid container direction = "row" justify = "space-between" alignItems = "stretch" spacing={8}>
                            
                                  { Adresa.map( (e,i) => (
                                        <Grid item xs = {e.xs} lg = {e.lg} md = {e.md} sm = {e.sm} key = {`adr-item-${i}`}>
                                            <TextField
                                                value = {this.state.item[e.value] || ''}
                                                onChange = { this.inputHandler(e.value)}
                                                className = {classes.textField}
                                                style = {{height: '3.2em'}}
                                                type = "text"
                                                InputProps={{ classes: {  input: classes.input } }}
                                                variant = "outlined"
                                                margin="normal"
                                                label = {e.label}
                                                dense = "true"  
                                                fullWidth = {true}               
                                            />
                                        </Grid>
                                   ))}

                             </Grid>
                        {/* </Paper>      */}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.submit(this.state.item)} color="primary" variant = "outlined"> <FaCheck /> &nbsp; CONFIRMĂ</Button>
                    </DialogActions>
                    {/* </Paper> */}
                </Dialog>
        )
    }
}

export default withStyles(styles)(ContactModal)
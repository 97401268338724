import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
// import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import { Editor } from '@tinymce/tinymce-react';
import Checkbox from '@material-ui/core/Checkbox'

const styles = theme => ({
    root: {},
    paper: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit * 2
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 140,
    },
    dense: {
        marginTop: 16,
    },
    input: { 
        fontSize: '0.75em',
        color: "navy",
        fontWeight: 800,
        '&::placeholder': {
            color: 'blue'
          }  
    },
})

class  ActForm extends React.Component {

    state = {
        isDateModifier: false
    }
    
    setDateModifier = () => this.setState({isDateModifier: !this.state.isDateModifier})

    render() {

        const { classes } = this.props
        console.log("ACT PROPS:", this.props)

        return  (
            <Grid container  direction = "row" justify="space-between" alignItems = "center">
                <Grid item xs = {12} sm = {12} lg={12}>
                    
                    <Paper className = {classes.paper}>
                    <Grid container direction = "row" justify = "flex-start" alignItems = "center">
                            <Grid item xs = {6} sm = {2} lg = {2}>
                                <TextField
                                value = { this.props.item.nr_doc}
                                onChange = { this.props.inputHandler('nr_doc')}
                                className = {classes.textField}
                                style = {{height: '3.2em'}}
                                type = "number"
                                InputProps={{ classes: {  input: classes.input } }}
                                variant = "outlined"
                                margin="normal"
                                label = "nr. act"
                                dense = "true"                   
                            />
                            </Grid>
                            <Grid item xs={6} sm = {2} lg = {2}>
                                <TextField 
                                    defaultValue = { this.props.item.data_doc}
                                    onChange = { this.props.inputHandler('data_doc')}
                                    className = {classes.textField}
                                    type = "date"
                                    InputProps={{ classes: {  input: classes.input } }}
                                    style = {{ width: 140}}
                                    variant = "outlined"
                                    margin="normal"
                                        label = "data înregistrării"
                                    dense = "true"                     
                                />
                            </Grid> 

                            <Grid item xs={12} sm = {5} lg = {5} >
                                <Grid container alignItems = "center">
                                <Checkbox color = "primary" checked = { this.state.isDateModifier || this.props.item.end }  onChange = {this.setDateModifier} />
                                <Typography variant = "caption"  color = "primary" >
                                    Acest document modifică perioada de valabilitate a contractului inițial
                                </Typography>
                                </Grid>
                            </Grid> 
                            
                            {  this.state.isDateModifier || this.props.item.end
                                ? (
                                    <Grid item xs={12} sm = {2} lg = {2}>
                                    <TextField 
                                        defaultValue = {( this.props.registru.filter( elem => elem.id ===  this.props.item.parent)[0]).end}
                                        onChange = { this.props.inputHandler('end')}
                                        className = {classes.textField}
                                        type = "date"
                                        InputProps={{ classes: {  input: classes.input } }}
                                        style = {{ width: 200}}
                                        variant = "outlined"
                                        margin="normal"
                                            label = "noua data de incetare"
                                        dense = "true"                     
                                    />
                                </Grid> 
                                )
                                : null
                            }
                            
                        </Grid>
                        <Grid item xs = {12}>
                            <Editor 
                            value = { this.props.item.props.obs }
                            style = {{height: '40vh'}}
                                onEditorChange = {  this.props.tinyHandler }
                                config={{
                                    plugins: 'autolink link image lists print preview',
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright',
                                    }}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(ActForm)
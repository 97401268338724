import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import landingimage from './assets/landingimage.svg'
import Config from './Config'
import axios from 'axios'

const config = Config[process.env.NODE_ENV];
const AxiosInstance = axios.create({
    method: 'POST',
    baseURL: config.API_PATH  +  '/is-token-valid',
    headers: {
      'Content-Type': 'application/json',
      'APP': 'ARENDA LOCAL'
    }
})

const styles = theme => {
    return ({
        root: 'flexGrow: 1',
        paper: {
            margin: theme.spacing.unit,
            padding: theme.spacing.unit * 3
        },
        dense: '16',
        input: {
            fontSize: '0.8em',
            color: "navy",
            fontWeight: 800,
            '&::placeholder': {
                color: 'blue'
            }
        },
        image: {
            width: "50%",
            height: 'auto'

        },
        paperError: {
            margin: theme.spacing.unit * 2,
            padding: theme.spacing.unit * 3,
            background: 'lightgrey',
            textAlign: 'center'
        }
    })
}

class Login extends React.Component {

    state = {
        error: null
    }

    async componentDidMount() {
        // console.log("will mount", this.props)
        let userdata = window.localStorage.getItem(config.NAMESPACE)
        if (userdata) { 
            this.props.history.push('/') 
        } else {
            let promise = null
            try {
                promise = await this.isTokenValid(this.props.match.params.login)
            } catch (err) { console.log("token validatation query err: ", err); this.setState({ error: 'eroare server autentificare' }) }
            finally {
                if ( !promise.data.ok ) {
                    this.setState({error: promise.data.msg})
                }  else {
                    this.setState({error: promise.data.data.name})
                    window.localStorage.setItem( config.NAMESPACE, JSON.stringify( {...promise.data.data, token: this.props.match.params.login}) )
                    this.props.history.push('/')

                }
                
            }
        }
    }

    async componentWillReceiveProps(newProps) {
        console.log("will receive props")
        let promise = null
        try {
            promise = await this.isTokenValid(newProps.match.params['login'])
        } catch (err) { console.log("token validatation query err: ", err); this.setState({ error: 'eroare server autentificare' }) }
        finally {
            console.log("promise result: ", promise)
            if ( !promise.data.ok ) {
                this.setState({error: promise.data.msg})
            }  else {
                console.log(promise.data)
                this.setState({error: promise.data.data.name})
                console.log("namespace:", config.NAMESPACE)
                window.localStorage.setItem( config.NAMESPACE, JSON.stringify(promise.data.data) )
            }
            
        }
    }

    isTokenValid = async (token) => {
        console.log(token, config.API_PATH)
        let promise = null
        AxiosInstance.interceptors.request.use(function (config) { 
            config.headers['x-auth-token'] = token
            return config
        })
        try {

            promise = await AxiosInstance()
        } catch (err) { console.log("token err: ", err) }
        finally {
            return promise
        }
    }

    render() {
        const { classes } = this.props;
        // console.log("this state: ", this.state)
        return (
            <Grid container direction="column" justify="center" alignItems="center" alignContent="center">

                <img src={landingimage} className={classes.image} alt = "landingpage"/>
                <br />
                <Typography variant="subheading" color="primary">
                    SE PARE CĂ NU TE-AI AUTENTIFICAT ÎNCĂ
                    </Typography>
                <Typography variant="subheading" color="primary">
                    PRIN SISTEMUL UNIC DE ACCES
                    </Typography>
                <Typography variant="subheading" color="primary">
                    WEBLOGIN.RO
                    </Typography>
                <Button variant="outlined" size="large" color="secondary" onClick={() => window.location.href = config.LOGIN_PORTAL}>
                    AUTENTIFICĂ-TE ACUM!
                    </Button>
                {this.state.error && this.props.match.params.login
                    ? 
                    <Paper className = {classes.paperError} style = {{width: '80%'}}>
                        <Typography variant = "subtitle1" color = "secondary" style = {{fontWeight: 800}}>
                            EROARE
                        </Typography>
                        <Typography variant = "subtitle2">
                            {this.state.error}
                        </Typography>
                    </Paper>
                    : null
                }

            </Grid>

        )
    }
}

export default withRouter(withStyles(styles)(Login))
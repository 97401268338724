import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter,  Route } from 'react-router-dom'
import Main from './components/Main/Main';
import Axios from './utils/Axios';

import Config from './Config'
 
const config = Config[process.env.NODE_ENV]

const styles = theme => ({
  root: {
    flexGrow: 1
  },

})

class App extends Component {

  state = { 
    registru: null, 
    contacte: null,
    dataLoaded: null, 
    error: null }

    componentWillMount() {

    }

  async componentDidMount() {

    let ls = window.localStorage.getItem(config.NAMESPACE)
    // console.log("ls",ls)
    if ( !ls ) { window.location.replace('login')}
   
    let result = null;
    try {
      result = await Axios({
        method: 'POST',
        url: 'registru',
        data: {},
      })
    } catch(err) { console.log("sql error: ", err)}
    finally {
      if( result ) {
        result = result.data;
        let p1=null, p2=null;
        if( result.ok && result.ok === true ) {
          
          result.data.registru.map( (e,i) => {
            // res=[];
            if ( e.parte1 ) { 
              e.parte1_parsed = JSON.parse(e.parte1) 
            }
            if (e.parte2) { 
              e.parte2_parsed = JSON.parse(e.parte2)
            }
  
            if( e.parent ) {
              p1 = result.data.registru.filter( elem => elem.id === e.parent )
              if ( p1.length > 0 ) { 
                result.data.registru[i]['parte1_parsed'] = p1[0].parte1_parsed;
                result.data.registru[i]['parte1'] = JSON.stringify(p1[0].parte1_parsed)
              }
              p2 = result.data.registru.filter( elem => elem.id === e.parent )
              if ( p2.length > 0 ) { 
                result.data.registru[i]['parte2_parsed'] = p2[0].parte2_parsed;
                result.data.registru[i]['parte2'] = JSON.stringify(p1[0].parte2_parsed)
              }          
            }
            if (e.props) {
              e.props = e.props  ? JSON.parse(e.props) : {}
              e.props.obs = e.props.obs ? e.props.obs : ''
            }
            return null;
          })
  
          // let cont = []
          // result.data.contacte.map( e => cont.push({label: e.nume, value: e.nume}) )
         
          this.setState( {
            registru: result.data.registru,
            contacte: result.data.contacte,
           
            dataLoaded: true,
            maxNumber: Math.max.apply(Math, [...result.data.registru].map( i => parseInt(i.nr_doc) ) )
          } )
        } else { this.setState({error: 'token invalid!'}) }
      } else {
          this.setState({error: 'Serverul nu raspunde. Vă rugăm să ne contactați cât mai urgent!'})
      }
   
    }

  }

  render() {
    // console.log(this.props)
    const { classes } = this.props; 
    
    return (
      <div className={classes.root}>
        {this.state.dataLoaded 
        ? 
       
            <Route path = "/" exact render = { () => <Main {...this.state} />} />
           
        : <div> conexiune în curs....</div>
        }

        {this.state.error ? <h1 style = {{color:'red'}}> {this.state.error} </h1> : null }

      </div>
    );
  }
}

export default withRouter(withStyles(styles)(App));

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import moment from 'moment';
// import Grid from '@material-ui/core/Grid';
// import Hidden from '@material-ui/core/Hidden';
import ReactTableInstance from '../Common/ReactTableInstance'
import Menu from '@material-ui/core/Menu'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa'

const styles = theme => ({
    root: { maxWidth: '100%'},
    paper: {
        margin: theme.spacing.unit
    },
    table: {
        marginTop: 0, 
        paddingTop: 0
    },
    buttonLeft:{
        fontSize: '0.7em',
        fontWeight: '450',
        padding: '4px',
        margin: 0, 
        width: '50%',
        color: 'red'
    },
    buttonRight:{
        fontSize: '0.7em',
        fontWeight: '450',
        padding: '4px',
        margin: 0, 
        width: '50%',
        color: 'green'
    },    
    buttonLg:{
        fontSize: '0.7em',
        fontWeight: '450',
        padding: '4px',
        margin: 0, 
        width: '100%',
        color: 'blue'
    }

})

class ListRenderer extends React.Component {

    state = {
        pageHeight: this.props.pageHeight,
        anchorEl: null,
        focus: {tip_doc: 'CONTRACT'}
    }

    componentWillReceiveProps(newProps) {
        // console.error("newProps")
        if ( newProps.pageHeight !== this.state.pageHeight ) {
            this.setState({pageHeight: newProps.pageHeight, data: newProps.data })
        }
    }

    linkHandler = item =>  {
        console.log("item:", item)
        let tip = null;

        switch(item.tip_doc) {
            case 'CONTRACT': tip = "contract"
            break;
            case 'ACT ADIȚIONAL': tip = "act-aditional"
            break;
            case 'REZILIERE': tip = "reziliere"
            break;
            case 'NOTIFICARE': tip = "notificare"
            break;
            default :tip = "contract"
            
        }
        console.log("item parent:", item.parent)
        if ( item.parent ) {
            return this.props.history.push(`/${tip}/${item.id}/${item.parent}`)
        } else {
            return this.props.history.push(`/${tip}/${item.id}`)
        }
    }
         
    render() {
        
        const { classes } = this.props;
        const registru = this.props.registru;
        const data = this.props.data;
        const { anchorEl } = this.state;
        const columns =  [
            { 
                Header: 'NUMĂRUL', 
                accessor: 'nr_doc', 
                Cell: e => ( 
                    <div style = {{textAlign: 'center'}}>
                        <Typography variant = "caption" color = "primary">
                            {e.original.nr_doc}
                        </Typography>
                    </div>
                ),
                width: 100

            } ,
            {   
                Header: 'DATA', 
                accessor: 'data_doc', 
                Cell: e => ( 
                    <div style = {{textAlign: 'center'}}>
                    <Typography variant = "caption" color = "primary">
                        {moment(new Date(e.original.data_doc)).format('DD.MM.YYYY')}
                    </Typography>
                    </div>
                ),
                width: 150
            },
            {   
                Header: 'TIP DOCUMENT', 
                accessor: 'tip_doc', 
                Cell: e => {
                    let nrAnexe = e.original.anexes && e.original.tip_doc === 'CONTRACT' ? ` ( ${e.original.anexes.length} anexe)`:''
                    return (
                        <Typography variant = "caption" color = "primary" style = {{ textAlign: 'center' }}>
                            {e.original.tip_doc + nrAnexe}
                        </Typography>
                    )
                },
                width: 200
            },
            {
                Header: 'CONTRACT PĂRINTE',
                accessor: 'parent',
                Cell: row => {
                    let parentId  = row.original.parent ? row.original.parent : null;
                    if ( parentId ) {
                        let parent = registru.filter( elem => elem.id === parentId )
                        if ( parent.length > 0 ) { 
                            parent = parent[0]
                            return (
                            <Button 
                                variant = "outlined" 
                                color = "primary" 
                                style = {{textAlign: 'center', width: '95%'}}
                                onClick = { () => this.props.history.push('/contract/'+parent.id)}
                                >
                                <Typography variant = "caption" color = "inherit">
                                    {parent.nr_doc} / {moment(parent.data_doc).format('DD.MM.YYYY')}
                                </Typography>
                            </Button>
                        )}
                    }

                },
                width: 150

            },
            { 
                Header: 'ARENDATOR(I)',
                // id: 'ardt',
                accessor: 'p1',
                Cell: row => {
                    let item = row.original.parte1_parsed ? row.original.parte1_parsed : []
                    return (
                    <div>
                         { item.map(
                             (e,i) => ( 
                                <Typography 
                                    variant = "caption" color = "primary"
                                    key = {`ardtx-${i}`}>{e.titular && e.titular.nume ? e.titular.nume : null }
                                </Typography>
                            )   
                        )}
                     </div>)
                },
                width: 250
            },
            { 
                Header: 'ARENDAȘ(I)',
                id: 'ards',
                accessor: 'p2',
                Cell: row => {
                    let item = row.original.parte2_parsed ? row.original.parte2_parsed : []
                    return (
                    <div>
                         { item.map(
                             (e,i) => ( 
                                <Typography 
                                    variant = "caption" color = "primary"
                                    key = {`ardtx-${i}`}>{e.titular && e.titular.nume ? e.titular.nume : null }
                                </Typography>
                             )   
                        )}
                     </div>)
                },
                width: 250
            },
            {
                Header: 'STARE ACT',
                width: 200,
                accessor: 'stare',
                Cell: e => {
                    let r = null;
                    if (e.original.tip_doc === 'CONTRACT' ) {
                        let id = e.original.id
                        let isReziliat = registru.filter( elem => elem.parent === id && elem.tip_doc === 'REZILIERE')
                        r = isReziliat.length > 0 ? true : false;
                    
                    if(r) { return (<Typography variant = "caption" color = "secondary"> REZILIAT LA {moment(isReziliat.data_doc).format('DD.MM.YYYY')}</Typography>) 
                    } else {return  (<Typography variant = "caption" color = "primary"> ÎN VIGOARE PÂNĂ LA {moment(e.original.end).format('DD.MM.YYYY')}</Typography>) }
                }         
                }
            },
            // {
            //     Header: 'ACȚIUNI',
            //     Cell: e => {
            //         return e.original.parent ? (
            //             <div style = {{display: 'flex'}}>
            //                     <Button variant = "outlined" className = {classes.buttonLeft} 
            //                         onClick = { this.linkHandler(e.original)  }>
            //                         EDITEAZĂ 
            //                     </Button>
                       
            //             <Button variant="outlined" size='small' className = {classes.buttonRight}
            //             onClick = { ()=> this.props.history.push('/contract/'+ e.original.parent )  }>
                          
            //                     {'CONTRACT ' + (registru.filter(el => el.id === e.original.parent))[0].nr_doc 
            //                     +'/'
            //                     +moment((registru.filter(el => el.id === e.original.parent))[0].data_doc).format('DD.MM.YYYY')}
                           
            //             </Button>
            //             </div>)
            //             : (
            //                 <Button  variant = "outlined" className = {classes.buttonLg}  
            //                 onClick = { ()=> this.props.history.push('/contract/'+ e.original.id )  }>
                           
            //                        EDITEAZĂ
                            
            //                 </Button>
                           
            //             )
            //     }
            // }
        ]
        return (
            <div className = {classes.root}>
               
            {data.length > 0 
                ? (
                    <div>
                        <ReactTableInstance 
                            
                            columns = {columns}
                            data = {data}
                            clicked = {ev=> {
                                console.log('clicked: ', ev)
                                this.linkHandler(ev)
                            }}
                            context = { (ev, item) => this.setState({ anchorEl: ev.currentTarget, focus: item }) } 
                            style = { {height: this.state.pageHeight} }
                            pageSize = {100}
                        
                        />
                    <Menu
                        id="context-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => this.setState({ anchorEl: undefined })}
                        style={{ zIndex: 99999, border: 'none' }}
                    >
                        <MenuList
                            component="nav"
                            subheader={
                                <ListSubheader style = {{background: 'navy', color: 'white'}}>
                                    <Typography variant = "subtitle2" color = "inherit" style = {{padding: '15px 0 15px 0'}}>
                                    ACȚIUNI &nbsp;
                                    { this.state.focus.tip_doc }
                                    &nbsp;
                                    { this.state.focus.nr_doc ? this.state.focus.nr_doc : null }
                                    &nbsp;/&nbsp;
                                    { this.state.focus.data_doc ? moment(this.state.focus.data_doc).format('DD.MM.YYYY') : null }
                                    </Typography>
                                </ListSubheader>
                            }
                        >
                        {this.state.focus && this.state.focus.tip_doc === 'CONTRACT'
                            ? (
                                <div>
                                    <MenuItem onClick={() => this.props.history.push("/act-aditional/nou/"+this.state.focus.id)}>
                                        <FaPlusCircle style={{ fontSize: '1.4em', color: 'navy' }} /> &nbsp;&nbsp; ADAUGĂ ACT ADIȚIONAL
                                    </MenuItem>
                                    <MenuItem onClick={() => this.props.history.push("/reziliere/nou/"+this.state.focus.id)}>
                                        <FaPlusCircle style={{ fontSize: '1.4em', color: 'navy' }} /> &nbsp;&nbsp;ADAUGĂ REZILIERE CONTRACT
                                    </MenuItem>
                                </div>
                            )
                            : null 
                        }
                            <MenuItem onClick = { () => {this.setState({anchorEl: null});this.props.remove(this.state.focus)} } >
                                <FaTimesCircle style={{ fontSize: '1.4em', color: 'red' }}/>
                                &nbsp;&nbsp;ȘTERGE ACEST DOCUMENT
                            </MenuItem> 
                        </MenuList>

                    </Menu>
                    </div>

                ) : null}
                        
            </div>

        )
    }
}

export default withRouter(withStyles(styles)(ListRenderer));
import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import landingimage  from '../../assets/landingimage.svg'
import { withRouter } from 'react-router-dom'
import  withWidth  from '@material-ui/core/withWidth'
import Button from '@material-ui/core/Button'

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
        // margin: theme.spacing.unit ,
        marginTop:'15px',marginBottom: '25px',
        textAlign: 'center',
        height: '83vh',
        color: theme.palette.text.secondary,  
        background: theme.palette.grey[50],
      
    },
    paper2: { margin: theme.spacing.unit, padding: theme.spacing.unit, textAlign: 'center' }
  });

  const getStyle = (index) => {
    const palette = [
        {background: "#dbe9d8", color: "black"},
        {background: "#c2d4d8", color: "black"},
        {background: "#f2efe8", color: "black"},
        {background: "#b0aac2", color: "black"},
        {background: "f2efe8", color: "black"},
        {background: "dbe9d8", color: "black"},
    ]
    return palette[index-1]
  }

const Landing = (props) => {

    const { classes } = props;
    console.log(props)

    let anexe = 0;
    [...props.registru].map( e => anexe += e.anexes.length)
    return (
        // <Paper className = {classes.paper} style = {{ height: `50vh` }}>
        <div>

            <Paper className = {classes.paper}>
                <Grid container direction = "row" justify = "flex-end" alignItems = "center"  style = {{height: '80vh'}}>
                    <Grid item xs = {12} md = {6} lg ={6}>
                            <img src = {landingimage} style = {{ 
                               width: ['xs', 'sm', ].includes(props.width) ?  '100vw': '50vw'
                            }}/>   
                    </Grid>
                    <Grid item xs = {12} md = {6} lg ={6} style = {{paddingTop: '40px'}}>
                        <Grid container justify = "flex-start" alignItems = "center">
                            <Grid item xs = {6} md = {4} lg = {4}>
                                <Paper className = {classes.paper2} style = {getStyle(1)}>
                                    <Typography variant = "display3" style = {getStyle(1)}>  {props.registru.length}</Typography>
                                    <Typography variant = "caption" style = {getStyle(1)}>  ÎNREGISTRĂRI</Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs = {6} md = {4} lg = {4}>
                                <Paper className = {classes.paper2} style = {getStyle(2)}>
                                        <Typography variant = "display3" style = {getStyle(2)}>  {props.registru.filter(e=>e.tip_doc === 'CONTRACT').length}</Typography>
                                        <Typography variant = "caption" style = {getStyle(2)}>  CONTRACTE</Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs = {6} md = {4} lg = {4}>
                                <Paper className = {classes.paper2} style = {getStyle(3)}>
                                        <Typography variant = "display3" style = {getStyle(3)}>  {props.registru.filter(e=>e.tip_doc === 'ACT ADIȚIONAL').length}</Typography>
                                        <Typography variant = "caption" style = {getStyle(3)}>  ACTE ADIȚIONALE</Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs = {6} md = {4} lg = {4}>
                                <Paper className = {classes.paper2} style = {getStyle(4)}>
                                        <Typography variant = "display3"style = {getStyle(4)}>  {props.registru.filter(e=>e.tip_doc === 'REZILIERE').length}</Typography>
                                        <Typography variant = "caption" style = {getStyle(4)}> REZILIERI</Typography>
                                </Paper>
                            </Grid>     
                            
                            <Grid item xs = {6} md = {4} lg = {4}>
                                <Paper className = {classes.paper2} style = {getStyle(3)}>
                                        <Typography variant = "display3" style = {getStyle(3)}>  {anexe}</Typography>
                                        <Typography variant = "caption" style = {getStyle(3)}> ANEXE/PARCELE</Typography>
                                </Paper>
                            </Grid>        

                            <Grid item xs = {6} md = {4} lg = {4}>
                                <Paper className = {classes.paper2} style = {getStyle(1)}>
                                        <Typography variant = "display3" style = {getStyle(1)}> {props.contacte.length}</Typography>
                                        <Typography variant = "caption" style = {getStyle(1)}> PERSOANE</Typography>
                                </Paper>
                            </Grid>    
                        </Grid>

                    </Grid>
                    {/* <Grid item xs = {12} style = {{textAlign: 'center', margin: '20px'}}>
                            <Button size = "large" variant = "outlined" color = "primary" onClick = {()=>props.history.push('/registru/all')}> 
                                REGISTRUL CONTRACTELOR DE ARENDARE 
                            </Button>  
                    </Grid>   */}
                </Grid> 

            </Paper>      
        {/* </Paper> */}
        </div>
        
    );
}

export default withRouter(withWidth()(withStyles(styles)(Landing)))
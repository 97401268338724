import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Config from '../../Config';
import { GoPerson } from 'react-icons/go';
import { withRouter } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden'
import { FaHandshake, FaHome, FaIdCard, FaListAlt, FaQuestionCircle, FaPowerOff, FaTimesCircle } from 'react-icons/fa'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'

const config = Config[process.env.NODE_ENV];

// userData = userData.name ? userData: {name: 'undefined'}

const styles = theme => {
    // console.log("TEMA:", theme)
    return ({
        root: {
            flexGrow: 1,
        },
        grow: {
            flexGrow: 1,
            
        },
        toolbar: {
            background: '#1b276b'
        },
        link: {
            textDecoration: 'none',
            color: 'white'
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
            color: theme.palette.common.white,
        },
        lgMenuButton: {
            width: 140,
            marginLeft: '5px',
            color: 'white'
        },
        menuItem: {
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                // '& $icon': {
                //     color: theme.palette.common.white,
                // },
            }
        },
        paper: {
            margin: theme.spacing.unit,
            padding: theme.spacing.unit
        },
        cell: {
            border: '1px dotted lightgrey',
            padding: theme.spacing.unit,
            margin: '-1px'
        },
        dialog: { height: 'auto', width: '100%' },
    }
    )
}

class Toolbartop extends React.Component  {

    state = { 
        anchorEl: null,
        menu: [
            { label: 'HOME', icon: 'FaHome', url: '/' },
            { label: 'REGISTRU', icon: 'FaHandshake', url: '/registru/all' },
            { label: 'AGENDĂ', icon: 'FaIdCard', url: '/contacte/all' },
        ],
        userData: null,
        userModalHandler: false
    }

    componentWillMount() {
        let userData = window.localStorage.getItem(config.NAMESPACE) 
        userData = userData ? JSON.parse(localStorage.getItem(config.NAMESPACE)) : null
        this.setState({userData: userData})

    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = name => value => {
        this.setState({ anchorEl: null });
        this.props.history.push(name);

    };

    logoutHandler = () =>{
        // this.props.history.push('/login')
        let lsItemName = config.NAMESPACE;
        console.log("lsItemNAme:", lsItemName)
        localStorage.removeItem(lsItemName)
        
        window.location.replace('/login')
        
    }

    render(){
        const { classes } = this.props;
        const { anchorEl } = this.state;
    
        return (

            <div className={classes.root} id = "react-no-print">
                <AppBar position="fixed" className = {classes.toolbar} >
                    <Toolbar>
                        <Grid container justify = "space-between" alignItems="center">
                        <div className={classes.grow}>
                            <Hidden only = {['lg','md','sm']}>
                                <Button
                                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleClick}
                                >
                                    <MenuIcon className = {classes.menuButton} />
                                </Button>

                                <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose('')}
                                >
                                    <MenuItem onClick={this.handleClose('/')}><FaHome style = {{fontSize: '1.5em'}} /> &nbsp; HOME</MenuItem>
                                    <MenuItem onClick={this.handleClose('/registru/all')}> <FaHandshake style = {{fontSize: '1.5em'}} /> &nbsp; REGISTRU</MenuItem>
                                    <MenuItem onClick={this.handleClose('/persoane/all')}><FaIdCard style = {{fontSize: '1.5em'}} /> &nbsp; PERSOANE</MenuItem>
                                    <MenuItem onClick={this.handleClose('/liste/all')}><FaListAlt style = {{fontSize: '1.5em'}} /> &nbsp; LISTE</MenuItem>
                                    <MenuItem onClick={this.handleClose('/ajutor/all')}><FaQuestionCircle style = {{fontSize: '1.5em'}} /> &nbsp; AJUTOR</MenuItem>
                                </Menu>
                            </Hidden>
                            
                            <Hidden only = "xs">
                                {/* {config.APP_NAME} */}
                                <Button 
                                    className = {classes.lgMenuButton}
                                    variant = "text"
                                    color = "primary"
                                    onClick={this.handleClose('/')}>
                                        <FaHome style = {{fontSize: '1.5em'}} /> 
                                        &nbsp; HOME
                                </Button>
                                <Button 
                                    className = {classes.lgMenuButton}
                                    variant = "text"
                                    color = "primary"
                                    onClick={this.handleClose('/registru/all')}> 
                                        <FaHandshake style = {{fontSize: '1.5em'}} />
                                        &nbsp; REGISTRU
                                </Button>                                
                                <Button 
                                    className = {classes.lgMenuButton}
                                    variant = "text"
                                    color = "primary"
                                    onClick={this.handleClose('/persoane/all')}>
                                        <FaIdCard style = {{fontSize: '1.5em'}} />
                                        &nbsp; PERSOANE
                                </Button> 
                                <Button 
                                    className = {classes.lgMenuButton}
                                    variant = "text"
                                    color = "primary"
                                    onClick={this.handleClose('/liste/all')}>
                                        <FaListAlt style = {{fontSize: '1.5em'}} />
                                        &nbsp; LISTE 
                                </Button>  
                                <Button 
                                    className = {classes.lgMenuButton}
                                    variant = "text"
                                    color = "primary"
                                    onClick={this.handleClose('/liste/all')}>
                                        <FaQuestionCircle style = {{fontSize: '1.5em'}} />
                                        &nbsp; AJUTOR 
                                </Button>                                                                  
                            </Hidden>
                            &nbsp;

                        </div>
                        <div>
                            <Button variant = "text" color = "inherit" onClick = { ()=>this.setState({userModalHandler: true})}>
                            <GoPerson /> &nbsp; {this.state.userData ? this.state.userData.name : null}
                            </Button>
                            
                            {/* <Button color="inherit"><AddIcon /></Button> */}
                            <Button color = "inherit" onClick = {this.logoutHandler}> <FaPowerOff /> </Button>
                        </div>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Dialog 
                    className = { classes.dialog }
                    maxWidth="md"
                    
                    open = {this.state.userModalHandler}
                    onClose = {() => this.setState({userModalHandler: false})}
                >
                    {/* <DialogTitle >
                        {this.state.userData ? this.state.userData.name : null}
                    </DialogTitle> */}
                    <DialogContent style = {{width: '900px'}}>
                    <Paper className = {classes.paper}>
                            <strong>Numele Dvs:</strong> {this.state.userData.name}
                        </Paper>                    
                        <Paper className = {classes.paper}>
                            <strong>Nume utilizator:</strong> {this.state.userData.username}
                        </Paper>
                        <hr/>
                        <br/>
                        {/* <Paper className = {classes.paper}> */}
                            <h3 className = {classes.paper }>ABONAMENTELE DVS.</h3> 
                        {/* </Paper> */}
                        <Grid container justify = "space-evenly" alignItems = "center">
                            <Grid item xs = {4}><div className = {classes.cell}><strong>APLICAȚIE</strong></div></Grid>
                            <Grid item xs = {4}><div className = {classes.cell}><strong>ROL</strong></div></Grid>
                            <Grid item xs = {4}><div className = {classes.cell}><strong>EXIPIRARE</strong></div></Grid>
                        </Grid>
                            {this.state.userData.apps.map( (e,i)=> (
                                <Grid container justify = "space-evenly" alignItems = "center" key = {`app-${i}`}>
                                    <Grid item xs = {4}><Paper className = {classes.cell}>{e.name}</Paper></Grid>
                                    <Grid item xs = {4}><Paper className = {classes.cell}>{e.role}</Paper></Grid>
                                    <Grid item xs = {4}><Paper className = {classes.cell}>{moment(e.expiry).format('DD.MM.YYYY')}</Paper></Grid>
                                </Grid>
                            ) )}
                       
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Toolbartop));
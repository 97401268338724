import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
import {FaTimesCircle} from 'react-icons/fa'
import Config from '../../Config'

import FileViewer from 'react-file-viewer';
import { Typography } from '@material-ui/core';
const config = Config[process.env.NODE_ENV]
const styles = theme => {

    return ({
        root: '',
        dialog: { height: 'auto', width: '100%' },
    })
}

const getExtension = (path) => {
    let extension = path ? path.split('.') : []
    if ( extension.length > 0 ) {
        extension = extension[extension.length-1]
    }
    console.log("extension: ", extension)
    return extension
}

class FilePreview extends React.Component {

    state = {
        open: false,
        data: null,
        error: null
    }

    componentWillMount() {
        this.setState({
            open: this.props.open || false,
            data: this.props.data
        })
    }

    componentWillReceiveProps(newProps) {
        console.log("will update!", newProps)
        if ( newProps.open !== this.state.open && newProps.data !== this.state.data ) {
            this.setState({
                open: newProps.open,
                data: newProps.data
            })
        }

    }
    close = () => this.setState({ open: false, data: null })
    
    onError = err => {
        console.log("error", err )
    }

    render() {
        const { classes } = this.props
        console.log("file path:",this.state.data &&  this.state.data.path ? config.API_PATH + '/getFile/' + encodeURI(this.state.data.path) : null)
        return (
            <Dialog 
                className={classes.dialog}
                open={this.state.open}
                maxWidth="md"
                onClose={ this.close }
                
                fullWidth={true}
            >
            <DialogTitle>
                <Grid container justify = "space-between" alignItems = "center">
                    <Typography variant = "display5">PREVIZUALIZARE</Typography>
                    <FaTimesCircle style = {{fontSize: '2em', color: 'lightgrey'}}  onClick = {this.close}/>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div>
                {this.state.data && this.state.data.path 
                    ? (
                        
                        <FileViewer key = "tessss"
                        fileType={getExtension(this.state.data.path)}
                        filePath={config.API_PATH + '/getFile/' + encodeURI(this.state.data.path)}
                        
                        // errorComponent={CustomErrorComponent}
                        onError={this.onError} />
                       
                    )
                    : null
                }
                </div>
            </DialogContent>

            </Dialog>
        )

    }

}

export default withStyles(styles)(FilePreview)
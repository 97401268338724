import React from 'react'
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import AddIcon from '@material-ui/icons/Add';
// import Button from '@material-ui/core/Button'
import ContractAnexaModal from './ContractAnexaModal'
import Interfaces from '../../../utils/interfaces';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import { Hidden } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import ConfirmDialog from '../../Common/ConfirmDialog/ConfirmDialog'
import Axios from '../../../utils/Axios'
import GCard from '../../Layout/GCard'
import { FaPlusSquare }  from 'react-icons/fa'
import ReactTableInstance from '../../Common/ReactTableInstance'

const styles = theme => { 
    // console.log("theme:", theme)
    return ({
        root: {       
        },
        row: {
            '&:hover': {
                background: theme.palette.grey[200]
            }
        },
        center: { textAlign: 'center', width: '10%'}
    })
}

class ContractAnexe extends React.Component {

    state = { 
        open: false, 
        focus : null, 
        index: null,
        dataLoaded: false,
        contract: null,
        menuVisible: false,
        confirmDialogOpen: false
    }

    componentDidMount() {
        this.setState({
            contract: {...this.props.contract}, 
            dataLoaded: this.props.dataLoaded
        })
    }

    // componentWillReceiveProps(newProps) {
    //     console.log("============================= received props ===============================") 
    //     console.log(newProps)

    //     if  (newProps.contract.anexes !== this.state.contract.anexes ) {
    //         console.log("DIFERIT!!")
    //         this.setState( {contract: newProps.contract } )
    //     }
    // }

    addAnexa = () => {
        let contract = {...this.state.contract}
        this.setState({
            focus: {...Interfaces.anexa, idreg: contract.id }
            }, this.setState({open: true}))
        this.scrollToBottom()
    }

    editAnexa = item => {
        this.setState({
            focus: item,
            open: true,
            menuVisible: false
        })
    }

    submit = async ev => {

        let promise = null;
        let contract = this.state.contract

        try{
            promise = await Axios({
                method: ev.id ? 'PATCH' : 'PUT',
                url: ev.id  ? '/update' : '/create',
                data: {
                    table: 'anexe',
                    data: {...ev, idreg: this.state.contract.id, ha: ev.ha ? parseFloat(ev.ha): 0}
                }
            })
        } catch(err) { console.log( "anexa sql insert err: ", err) }
        finally {
           
            let response = {...promise.data.data}
            let id = response.id

            if ( !ev.id ) {
                
                contract.anexes.push(response)
            } else { 
                let currentItem = contract.anexes.filter( elem => elem.id === id )[0]
                console.log("updated item: ", contract.anexes[contract.anexes.indexOf(currentItem)])
                contract.anexes[contract.anexes.indexOf(currentItem)] = response;
            }
            
            this.setState({open: false})
            console.log("RESPONSE FOR ADD: ", contract)
            this.props.childHandler(contract)
            
        }
        
    }

    openConfirmationMenu = (event, item) => {
        this.setState({
            confirmationAnchor: event.currentTarget,
            menuVisible: true,
            focus: item
        })
    }

    removeAnexaConfirmationModalHandler = () => {
        this.setState( {confirmDialogOpen: true} )
    }

    removeAnexaHandler = async ev => {
        let promise = null;
        if ( ev === true ) {
            try {
                promise = await Axios({
                    method: 'POST',
                    url: '/remove',
                    data: {
                        table: 'anexe',
                        data: { id: this.state.focus.id }
                    }
                })
            } catch (err) { console.log("anexa deletion err: ", err)}
            finally {
                    console.log("deleted id: ", promise.data.id)
                    let contract = this.state.contract;
                    let anexes = [...contract.anexes]
                    
                    let targetItem = anexes.filter( elem => elem.id === promise.data.id)[0]
                    anexes.splice([...contract.anexes].indexOf(targetItem),1)
                    contract.anexes = anexes
                   
                    this.setState({menuVisible: false, confirmationAnchor: null, confirmDialogOpen: false })
                    this.props.childHandler(contract)
                    // this.scrollToBottom()
                
            }
        } else { 
            this.setState({menuVisible: false, confirmationAnchor: null, confirmDialogOpen: false })
        }
        
    }

    cloneAnexaHandler = async () => {
        let promise = null

        try {
            promise = await Axios({
                method: 'PUT',
                url: '/create',
                data: {
                    table: 'anexe',
                    data: {...this.state.focus, id: null}
                }
            })
        } catch(err) { console.log("cloning err: ", err )}
        finally {
            if ( promise.data.ok ) {
                console.log("ok:", promise.data.data)
                let contract = this.state.contract
                contract.anexes.push(promise.data.data)
                // this.setState({contract:contract})
                this.props.childHandler(contract)
            }
        }

        let contract = this.state.contract;
        let anexa = {...this.state.focus};
        contract.anexes.push(anexa)
        this.props.childHandler(contract)
        this.setState({menuVisible: false, confirmationAnchor: null})
    }

    closeAnexa = () => {
        this.setState({
            open: false,
            focus: null,
        })
        this.scrollToBottom()
    }

    handleConfirmationClose = () => {
        this.setState({menuVisible: false, confirmationAnchor: null, focus: null})
    }

    scrollToBottom = () => {
        this.lastRow.scrollIntoView({ behavior: "smooth" });
      }

    render() {

        console.log("anexe state: ",this.state)
        const { classes } = this.props;

        const header = (
            <Grid container direction = "row" justify = "space-between" alignItems = "center">
                <div>ANEXE (
                    {this.state.contract ? this.state.contract.anexes.length : null}
                    )
                </div>
                { this.state.contract && this.state.contract.id
                    ? (
                        <div
                            onClick = { this.addAnexa } >
                            <FaPlusSquare style = {{fontSize: '1.2em'}}/>
                        </div>
                    )
                    : (
                        <Typography variant = "caption" color = "secondary"> salvați contractul pentru a putea adăuga anexe noi</Typography>
                    )
                }

            </Grid>
        )

        const columns = [
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'Nr.doc',
                accessor: 'nr_act'
            },
            {
                Header: 'Tarlaua',
                accessor: 'tarlaua'
            },
            {
                Header: 'Parcela',
                accessor: 'parcela'
            },
            {
                Header: 'Suprafața(ha)',
                accessor: 'ha'
            }           
        ]

        return (
            <GCard header = {header}  cardStyle = {{}} className = {classes.root}>
                
                    <Grid container direction = "row" justify = "space-around" alignItems = "flex-end">
                        <Grid item xs = {12}>
                            
                            { this.state.dataLoaded && this.state.contract
                                ? (
                                    <ReactTableInstance 
                                        columns = {columns}
                                        data = {this.state.contract.anexes}
                                        clicked = { (item,ev)=>{ return item ? this.editAnexa(item) : null } }
                                        context = { (ev, item)=>{return item ? this.openConfirmationMenu(ev,item): null }}
                                        pageSize = {this.state.contract.anexes.length}
                                        filterable = "no"
                                        pagination = "no"
                                        style = {{ height: '14vh'}}
                                    />
                                )
                                : null
                            }
                    </Grid>

                    <Menu id = "confirmation-menu" anchorEl = {this.state.confirmationAnchor} open = {this.state.menuVisible} onClose = {this.handleConfirmationClose}>
                        <MenuItem onClick = { ()=>this.editAnexa(this.state.focus) }>
                            <Typography color = "default" variant = "caption">
                                Editează 
                                {this.state.focus && this.state.focus.parcela ? ` parcela: ${this.state.focus.parcela} `:null }
                                {this.state.focus && this.state.focus.tarlaua ? ` tarlaua: ${this.state.focus.tarlaua} `:null }
                                {this.state.focus && this.state.focus.ha ? ` suprafața: ${this.state.focus.ha} ha`:null }
                            </Typography>
                        </MenuItem>                    
                        <MenuItem onClick = { this.removeAnexaConfirmationModalHandler }>
                            <Typography color = "secondary" variant = "caption">
                                Șterge 
                                {this.state.focus && this.state.focus.parcela ? ` parcela: ${this.state.focus.parcela} `:null }
                                {this.state.focus && this.state.focus.tarlaua ? ` tarlaua: ${this.state.focus.tarlaua} `:null }
                                {this.state.focus && this.state.focus.ha ? ` suprafața: ${this.state.focus.ha} ha`:null }
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick = { this.cloneAnexaHandler }>
                            <Typography color = "primary" variant = "caption">
                                
                                Clonează  
                                {this.state.focus && this.state.focus.parcela ? ` parcela: ${this.state.focus.parcela} `:null }
                                {this.state.focus && this.state.focus.tarlaua ? ` tarlaua: ${this.state.focus.tarlaua} `:null }
                                {this.state.focus && this.state.focus.ha ? ` suprafața: ${this.state.focus.ha} ha `:null }
                            </Typography>
                        </MenuItem>                                
                        
                    </Menu>

                    <ConfirmDialog 
                        open = {this.state.confirmDialogOpen}
                        onClose = {ev=> this.removeAnexaHandler(ev)} >
                        Anexa selectată va fi ștearsă definitiv !!
                    </ConfirmDialog>

                            <ContractAnexaModal 
                                data = {this.state.focus}
                                open = {this.state.open}
                                close = {this.closeAnexa}
                                submit = {this.submit}
                                inputHandler = {this.inputHandler}
                            />

                                <div ref={(el) =>  {this.lastRow = el} } >   </div>
                    {/* </div> */}
                </Grid>

            </GCard>
        )
    }
}

export default withStyles(styles)(withWidth()(ContractAnexe));
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import BottomBar from '../Layout/BottomBar'

const styles = {
    root: { flexGrow:1 },
    style: {},
    styleHeader: {},
    styleBody: {},
    styleCell: {},
    styleRow: {}
}

const split = (arr, n) => {
    var res = [];
    while (arr.length) {
    res.push(arr.splice(0, n));
    }
    return res;
}

class GTable extends React.Component {

    // state = {
    //     columns: this.props.columns ? this.props.columns : [],
    //     data: this.props.data ? this.props.data : [],
    //     perPage: this.props.perPage ? this.props.perPage : 10,
    //     style: this.props.style || {},
    //     styleHeader: this.props.styleHeader || {},
    //     styleBody: this.props.styleBody || {},
    //     styleCell: this.props.styleCell || {},
    //     styleRow: this.props.styleRow || {},
    // }

    componentWillMount() {
        this.setState({
            columns: this.props.columns,
            data: this.props.data,
            perPage: this.props.perPage || 15,
            style: this.props.style,
            styleHeader: this.props.styleHeader,
            styleBody: this.props.styleBody,
            styleCell: this.props.styleCell,
            styleRow: this.props.styleRow,
        })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            columns: newProps.columns,
            data: newProps.data,
            perPage: newProps.perPage || 15,
            style: newProps.style,
            styleHeader: newProps.styleHeader,
            styleBody: newProps.styleBody,
            styleCell: newProps.styleCell,
            styleRow: newProps.styleRow,
        })
    }

    render() {
        console.log("table state", this.props)
        const { classes }  = this.props;

        const header = (
            <TableRow>
            {   
                this.state.columns.map( (e,i) => {
                    return !!e.visible || typeof e.visible === 'undefined'  ? (
                        <TableCell key = {`head-${i}`}>
                            { e.Header}
                        </TableCell>    
                    ) : null
                })
            }
            </TableRow>
        )

        const rows = (data) => {
          
            let res = []
            data.map( (e,i) => (
                res.push(
                    <TableRow key = {`row-${i}`}>
                        {row(e,i)}
                    </TableRow>
                )
            ))
               
            return res
        }

        const row = (rowData, rowIndex) => {
            let res = []
            this.state.columns.map( (col,iCol) => (
                res.push(
                <TableCell key = {`cell-${iCol}-${rowIndex}`} >
                   {col.Cell 
                        ? col.Cell(rowData)
                        : rowData[col.accessor]
                    }
                </TableCell>)
            ))
            return res
        }

        const segmentedData = split([...this.state.data], this.state.perPage)
        console.log("segments:", segmentedData)

        return (
            <div>
                {segmentedData.map( (e,i) => (
                    <div key = {`chunk-${i}`} style = {{pageBreakAfter: "always"}}>
                    <Table >
                        <TableHead>
                            {header}
                        </TableHead>

                        <TableBody>
                            { rows(e, i, this.state.perPage )}
                        </TableBody>
                    </Table>
                    {/* <BottomBar> */}
                        <Grid container direction = "row" justify="space-between" alignItems = "center" style = {{background: 'lightGrey', padding: '5px',         position: 'relative',
        marginTop:'calc(20px)'}}>
                            <div style = {{fontSize: '0.7em' }}> 
                                Rapor generat de dezvoltată de SIGN Portal srl & Maftei Gabriel-Claudiu PFA Arad  prin <strong>arendare.ro</strong>
                            </div>
                            <div style = {{fontSize: '0.7em' }}> 
                                Pagina {i+1}
                            </div>
                        </Grid>
                        
                    {/* </BottomBar> */}
                    </div>
                   
                ))}
            </div>

        )
    }
}

export default withStyles(styles)(GTable)
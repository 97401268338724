
import React from 'react'
import moment from 'moment'

const Docs =  [
        {
            id      : 1,
            name    : 'Registru general - sintetic',
            columns : [
                {Header: 'Id'       , accessor: 'id', visible: true },
                {Header: 'Nr'       , accessor: 'nr_doc', visible: true },
                {Header: 'Data'     , accessor: 'data_doc'},
                {Header: 'Start'    , accessor: 'start'     , Cell: row => (moment(row.start).format('DD.MM.YYYY'))},
                {Header: 'End'      , accessor: 'end'       , Cell: row => (moment(row.end).format('DD.MM.YYYY'))},
            ], 
            data: 'registru'

        },
        {
            id      : 2,
            name    : 'Registru general - sintetic2',
            columns : [
               
                {Header: 'Nr'       , accessor: 'nr_doc', visible: true },
                {Header: 'Data'     , accessor: 'data_doc'},
                {Header: 'Start'    , accessor: 'start'     , Cell: row => (moment(row.start).format('DD.MM.YYYY'))},
                {Header: 'End'      , accessor: 'end'       , Cell: row => (moment(row.end).format('DD.MM.YYYY'))},
            ], 
            data: 'registru'

        },
        {
            id      : 3,
            name    : 'Registru persoane',
            columns : [
               
                {Header: 'Id'       , accessor: 'id', visible: true },
                {Header: 'Nume'     , accessor: 'nume'},
                {Header: 'Localitatea'    , accessor: 'loc' },
                {Header: 'Tara'      , accessor: 'tara'  }
            ], 
            data: 'contacte'

        }
    ]

export default Docs
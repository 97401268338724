import React from 'react'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import NoOptionsMessage  from './NoOptionsMessage'

const styles = theme => ({
    select: {
        placeholder: {
            fontSize: '10px'
        }
    }
})

const ContactSelect = props => {

        const { classes } = props
        return (
            <div>
                <Select 
                    options = {props.contacte}
                    value = { props.value}
                    style = {{flexGrow:1}}
                    onChange = {props.onChange}
                    placeholder = "caută contact existent..."
                    isSearchable = {true}
                    styles  = {{
                        control: styles => ({ ...styles, fontSize: '10px', color: 'red' }),
                        option: styles => ({ ...styles, fontSize: '10px',   color: 'navy'}),
                        input: styles => ({ ...styles, fontSize: '10px' }),
                        placeholder: styles => ({ ...styles, fontSize: '10px' }),
                    }}
                    components={{ NoOptionsMessage  }}
                    
                />

            </div>
        )
    }
    
export default withStyles(styles)(ContactSelect)
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => (
    {
        dialog: { height: 'auto', width: '100%' },
        textField: {
            // marginLeft: theme.spacing.unit,
            // marginRight: theme.spacing.unit,
            // width: '150',
        },
        dense: '16',
        input: {
            fontSize: '0.8em',
            color: "navy",
            fontWeight: 800,
            '&::placeholder': {
                color: 'blue'
            }
        },
    })

const row1 = [
    { value: 'nr_act', label: "Nr. act" },
    { value: 'tarlaua', label: "Tarlaua" },
    { value: 'parcela', label: "Parcela" },
    { value: 'bloc_fizic', label: "Bloc fizic" },
    { value: 'ha', label: "Supr." },
    { value: 'categoria', label: "Categoria" },
];
const row2 = [
    { value: 'vecin_n', label: "Vecin Nord" },
    { value: 'vecin_e', label: "Vecin Est" },
    { value: 'vecin_s', label: "Vecin Sud" },
    { value: 'vecin_v', label: "Vecin Vest" },
];

class ContractAnexaModal extends React.Component {

    state = {
        open: false,
        data: null,
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            open: this.props.open
        })
    }

    componentWillReceiveProps(newProps) {
        if ( newProps !== this.props) {
            this.setState(oldState => {return {...oldState, ...newProps}})
        }
    }

    close = () => {
        this.setState({open: false})
        this.props.close()
    }

    inputHandler = name => event => {
        let data = this.state.data
        data[name] = event.target.value
        this.setState({data:data})
    }

    submit = () => {
        this.setState({open: false})
        this.props.submit(this.state.data)
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog className={classes.dialog}
                maxWidth="md"
                onClose={this.state.close}
                open={this.state.open}
                fullWidth={true}

                onBackdropClick={ ()=>this.close() }>

                <DialogTitle>
                    ANEXA
                    </DialogTitle>
                <DialogContent>
                    {this.state.data
                        ? (
                            <Grid container direction="row" justify="space-between" alignItems="stretch" spacing = {8}>
                                {row1.map((e, i) => (
                                    <Grid item xs={12} sm={2} lg={2} key={`row-${i}`}>
                                        <TextField
                                            value={this.state.data[e.value]}
                                            onChange={this.inputHandler(e.value)}
                                            className={classes.textField}
                                            type="text"
                                            InputProps={{ classes: { input: classes.input } }}
                                            variant="outlined"
                                            margin="normal"
                                            label={e.label}
                                            fullWidth = {true}
                                        />
                                    </Grid>
                                ))}

                                {row2.map((e, i) => (
                                    <Grid item xs={12} sm={12} lg={12} key={`row-${i}`}>
                                        <TextField
                                            value={this.state.data[e.value]}
                                            onChange={this.inputHandler(e.value)}
                                            className={classes.textField}
                                            fullWidth = {true}
                                            type="text"
                                            InputProps={{ classes: { input: classes.input } }}
                                            variant="outlined"
                                            margin="normal"
                                            label={e.label}
                                        />
                                    </Grid>
                                ))}

                            </Grid>
                        )
                        : null
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.close} color="primary"> ÎNCHIDE</Button>
                    <Button onClick={this.submit} color="primary"> CONFIRMĂ</Button>
                </DialogActions>
                {/* </Paper> */}
            </Dialog>

        )
    }
                
}

export default withStyles(styles)(ContractAnexaModal)
const Config = {
    
    development: {
        APP_NAME: 'ARENDA',
        NAMESPACE: 'arenda',
        API_PATH: 'http://localhost:3200',
        LOGIN_PORTAL: 'https://weblogin.ro'
    },
    production: {
        APP_NAME: 'ARENDA',
        NAMESPACE: 'arenda',
        API_PATH: 'http://localhost:3200',
        LOGIN_PORTAL: 'https://weblogin.ro'
    }
}

export default Config;
import React from 'react'
import Paper from '@material-ui/core/Paper'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme =>{
    return ({
        root: {padding: theme.spacing.unit, textAlign: 'center'},
        
    })
}

class NoOptionsMessage  extends React.Component {

    state = {open: false}

    close = () => this.setState({open:false})
    
    render() {
        console.log("no options state: ", this.state)
        const {classes}  = this.props;

        return (
            <div className = {classes.root}>
                <Typography variant = "caption" color = "secondary">Persoana nu există. Folosiți opțiunea [adăugare]</Typography>

            </div>
        )}
}

export default withStyles(styles)(NoOptionsMessage)
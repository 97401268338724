import React from 'react'
import ReactDom from 'react-dom'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import moment from 'moment'

import Axios from '../../../utils/Axios'
import CustomToolbar from '../../Layout/CustomToolbar';
import Interfaces from '../../../utils/interfaces'
import MainContent from '../../Layout/MainContent'
import Main from '../../Main/Main';
import SumarContract from './SumarContract';
import ActForm from './ActForm'
import ContractChooser from './ContractChooser'
import Istoric from './ContractIstoric'
import ContractIstoric from './ContractIstoric';
import Confirmation from '../../Common/Confirmation'

const styles = theme => {

    return ({
        root: {},
        paper: {
            margin: theme.spacing.unit,
            padding: theme.spacing.unit
        }
    })
}

class Reziliere extends React.Component {

    state = {
        item: null,
        dataLoaded: false,
        showSelect: false,
        registru: null, 
        confirmation: false
    }

    componentWillMount() {
        // console.error("mounted", this.props)
        let parentId = this.props.match.params.parent
        let itemId = this.props.match.params.id
        this.setState({registru: this.props.registru},
            this.prepareObject(itemId, parentId)
        )
        
    }

    componentWillReceiveProps(newProps) {
        console.error("new props received by act aditional!!!")
        let parentId = newProps.match.params.parent
        let itemId = newProps.match.params.id
        this.setState({registru: this.props.registru},
            this.prepareObject(itemId, parentId)    
        )
      }    

    prepareObject(itemId, parentId) {
        // console.log("PREPARE!!!", itemId, parentId)
        let item = {
            ...Interfaces.conex,
            contract: null,
            istoric: null,
            tip_doc: 'REZILIERE',
            nr_doc : parseInt(this.props.maxNumber)+1
        };
        let existingItem = {}, contract = {}, istoric = {}

        if (itemId === 'nou') {
            item.data_doc = moment().format('YYYY-MM-DD')
            item.props = { obs: '' }
        }
        else {
            existingItem = this.props.registru.filter(elem => elem.id === parseInt(itemId))
            if (existingItem.length > 0) { 
                existingItem = existingItem[0] 
                existingItem.props = existingItem.props ? existingItem.props : {obs: ''}
            }

        }

        if (parentId) {
            contract = this.props.registru.filter(elem => elem.id === parseInt(parentId))
            if ( contract.length > 0 ) {
                contract = contract[0]
                item.parent = contract.id
            }

            // console.log("CONTRACT:::::::::", contract)
            istoric = this.props.registru.filter(elem => elem.parent === parseInt(parentId))
            
            item = { ...item, ...existingItem, contract: contract, istoric: istoric }
        } else {
            this.setState({showSelect: true})
        }
        this.setState({
            item: item,
            dataLoaded: true
        })

        return null
    }

    handleContractChosen = ev => {
        let item = this.state.item
        item.parent = ev.id
        item.contract = ev
        item.parte1_parsed = JSON.parse(ev.parte1)
        item.parte2_parsed = JSON.parse(ev.parte2)
        this.setState({ item: item })

    }

    inputHandler = name => ev => {
        let item = this.state.item
        item[name] = ev.target.value
        this.setState({ item: item })
    }

    tinyHandler = ev => {
        let item = this.state.item
        item.props = { obs: ev }
        this.setState({ item: item })
    }

    submit = async () => {
        let result = null;
        //prepare object 
        let data = { ...this.state.item }
        data.parte1 = JSON.stringify(data.parte1_parsed)
        data.parte2 = JSON.stringify(data.parte2_parsed)
        data.props = JSON.stringify(data.props)
        delete (data['contract'])
        delete (data['parte1_parsed'])
        delete (data['parte2_parsed'])
        console.log(data)

        try {
            result = await Axios({
                method: data.id ? 'PATCH' : 'PUT',
                url: data.id ? '/update' : '/create',
                data: {
                    table: 'registru',
                    data: data
                }

            })
        } catch (err) { console.log("aditional err: ", err) }
        finally {
            // console.log("result:", result.data.data)
            let r = {...result.data.data}
            r.props = JSON.stringify(r.props)
            let item = { ...this.state.item, ...r}

            let registru = this.state.registru
            let regItem = registru.filter( elem => elem.id === r.id )
            console.log("regitem: ", regItem)
            if ( regItem.length > 0 ) {
                // exists
               let selectedItem = registru[registru.indexOf(regItem[0])]
               selectedItem.props = {obs: this.state.item.props.obs}
               selectedItem.nr_doc = this.state.item.nr_doc
               selectedItem.data_doc = this.state.item.data_doc
            } else {
                item.props = {obs: this.state.item.props.obs}
                registru.push(item)
            }
            
            // console.log("item after submision: ", item)
            // console.log("registru after submision: ", registru)
            this.setState({item: item, registru: registru, confirmation: true }, this.prepareObject(item.id, item.parent))
            
        // this.prepareObject('nou')
        }

    }

    render() {

        const { classes } = this.props
        // console.log("ITEM============>", this.state.item)
        return (
            <Grid container direction="column">
            <Confirmation open = {this.state.confirmation} text = "REZILIEREA A FOST ÎNREGISTRATĂ!"></Confirmation>
                <CustomToolbar color="default" position = "fixed" style = {{marginTop:'60px'}}>
                    <div>
                        <Fab size="small" onClick={() => this.props.history.push('/registru/all')}> <ArrowBackIcon /></Fab>
                        &nbsp; &nbsp; &nbsp;
                    {this.props.match.params.id === 'nou' ? "ADĂUGARE " : "VIZUALIZARE "}
                    
                    {this.state.item && this.state.item.tip_doc ? this.state.item.tip_doc : 0}
                     &nbsp; NR. &nbsp;
                    {this.state.item && this.state.item.nr_doc ? this.state.item.nr_doc + " " : 0}
                    /
                    {this.state.item && this.state.item.data_doc ? " " + moment(this.state.item.data_doc).format('DD.MM.YYYY') : 0}

                    </div>
                    <Fab
                        size="small"
                        onClick={this.submit}
                        disabled={!this.state.item.parent || !this.state.item.props.obs}>
                        <CheckIcon />
                    </Fab>
                </CustomToolbar>

                <MainContent>
                    {this.state.dataLoaded
                        ? (
                            <Paper>
                                <Grid container direction="row" justify="space-between" alignItems="stretch" spacing = {0}>
                                    <Grid item xs={12} sm={12} lg={12}>
                                        { this.state.showSelect
                                            ?  <ContractChooser
                                                    {...this.props}
                                                handleContractChosen={this.handleContractChosen}
                                                />
                                            : null
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={6}>
                                    {/* <Paper className = {classes.paper} style = {{height:'100%'}}> */}
                                            { this.state.item.contract && this.state.item.contract.id
                                                ? <SumarContract {...this.state.item} />
                                                :  <div>nu ați selectat nici un contract!</div>
                                            }
                                            {/* </Paper> */}
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={6}>
                                            {this.state.item.contract && this.state.item.contract.id
                                                ? <ContractIstoric params = {{ id: this.state.item.contract.id }} registru = { this.state.registru} />
                                                : null
                                            }
                                    </Grid>

                                </Grid>

                                <ActForm style = {{marginTop: '50px'}}
                                    {...this.state}
                                    inputHandler={this.inputHandler}
                                    tinyHandler={this.tinyHandler}
                                />
                                
                            </Paper>

                        )
                        : null
                    }

                </MainContent>

            </Grid>

        )
    }

}

export default withRouter(withStyles(styles)(Reziliere))
import React from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";

const ReactTableInstance = (props) => (

        <div style = {{fontSize: '12px'}}>
            {props.data && props.columns
                ? (
                    <ReactTable 
                    data = {props.data} 
                    columns = {props.columns}  
                    defaultPageSize={props.pageSize || 10}
                    filterable = {props.filterable && props.filterable === "no" ? false : true}
                    showPagination = {props.pagination && props.pagination === "no" ? false : true}
                    pageText = "pagina"
                    ofText = "din"
                    rowsText = "rânduri"
                    previousText = "anterioară"
                    nextText = "următoare"
                    noDataText = "informațiile solicitate nu există"
                    style = { props.style }
                    getTdProps={(state, rowInfo) => {
                        
                        return {
                          onClick: (e, handleOriginal) => {
                            // console.log("It was in this row:", rowInfo);
                            props.clicked(rowInfo && rowInfo.original ? rowInfo.original : null)
                          },
                          onContextMenu: e => {e.preventDefault(); props.context(e, rowInfo && rowInfo.original ? rowInfo.original : null)}
                          
                        }
                    }}
                    
                        className="-striped -highlight"/>
                )
                : null
            
            }

        </div>
    
)

export default ReactTableInstance
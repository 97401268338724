import React from 'react';
import { withRouter } from 'react-router-dom';
import Filter from './Filter'
import ListRenderer from './ListRenderer'
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import Fab from '@material-ui/core/Fab';
import { intersection } from 'lodash'
import moment from 'moment'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FaPlus, FaPlusCircle } from 'react-icons/fa';
import Axios from '../../utils/Axios'
import ConfirmDialog from '../Common/ConfirmDialog/ConfirmDialog'
import { Typography } from '@material-ui/core';

class Registru extends React.Component {

    state = {
        registru: null,
        filter: null,
        data: null,
        addModalOpen: false,
        anchorEl: null,
        dataLoaded: null,
        filterVisible: false,
        pageHeight: 600,
        confirmationOpen: false
    }

    componentDidMount() {
        let data = this.props.registru
        this.setState({
            data: data,
            dataLoaded: true
        })
    }

    componentWillMount() {
        // console.log("REGISTRU MOUNTED:\n", this.props)
        this.setState({ filter: this.props.match.params, registru: [...this.props.registru] }, () => {
            let data = this.filterByContract(this.props)
            this.setState({
                data: data,
                filter: this.props.match.params
            })
        })

    }

    componentWillReceiveProps(props) {

        // this.setState( { filter : this.props.match.params, registru: []  } )
        console.log("will receive", props)
        let data = this.filterByContract(props)

        this.setState({ data: data, dataLoaded: true })
    }

    filterByContract(props) {

        let f_tip_doc = [], f_name = [], f_date = []
        let a_nr_act = [], a_parcela = [], a_tarlaua = [], a_categoria = [], a_bloc_fizic = [], a_ha = []
        let match = props.match.params;

        //tip doc
        if (match.tip_doc === 'all') {
            f_tip_doc = this.props.registru;
        } else {
            f_tip_doc = props.registru.filter(e => e.tip_doc.toLowerCase() == props.match.params.tip_doc.toLowerCase())
        }

        //date
        if (match.start && match.end) {
            let start = moment(match.start).unix(), end = moment(match.end).unix();
            let dt = null;
            f_date = props.registru.filter(e => {
                dt = moment(e.data_doc).unix()
                return (start < dt && end > dt)
            })
        } else { f_date = this.props.registru }

        //person 
        if (!!match.nume && match.nume !== 'null' && typeof match.nume !== 'undefined') {
            f_name = props.registru.filter(e => (e.parte1 && e.parte1.indexOf(match.nume) > 0) || (e.parte2 && e.parte2.indexOf(match.nume) > 0))
        } else {
            f_name = this.props.registru
        }

        // ===== filtru anexe ====
        // console.log(match)
        if (match.nr_act && match.nr_act !== "null") {
            a_nr_act = props.registru.filter(e => e.anexes.length > 0 && (e.anexes.filter(elem => elem.nr_act === match.nr_act)).length > 0)
        } else { a_nr_act = this.props.registru }
        if (match.parcela && match.parcela !== "null") {
            a_parcela = props.registru.filter(e => e.anexes.length > 0 && (e.anexes.filter(elem => elem.parcela === match.parcela)).length > 0)
        } else { a_parcela = this.props.registru }
        if (match.tarlaua && match.tarlaua !== "null") {
            a_tarlaua = props.registru.filter(e => e.anexes.length > 0 && (e.anexes.filter(elem => elem.tarlaua === match.tarlaua)).length > 0)
        } else { a_tarlaua = this.props.registru }
        if (match.ha && match.ha !== "null") {
            a_ha = props.registru.filter(e => e.anexes.length > 0 && (e.anexes.filter(elem => parseFloat(elem.ha) === parseFloat(match.ha))).length > 0)
        } else { a_ha = this.props.registru }
        if (match.categoria && match.categoria !== "null") {
            a_categoria = props.registru.filter(e => e.anexes.length > 0 && (e.anexes.filter(elem => elem.categoria.toLowerCase() === match.categoria.toLowerCase())).length > 0)
        } else { a_categoria = this.props.registru }
        if (match.bloc_fizic && match.bloc_fizic !== "null") {
            a_bloc_fizic = props.registru.filter(e => e.anexes.length > 0 && (e.anexes.filter(elem => elem.bloc_fizic.toLowerCase() === match.bloc_fizic.toLowerCase())).length > 0)
        } else { a_bloc_fizic = this.props.registru }

        let intersect = intersection(this.props.registru,
            f_tip_doc,
            f_date,
            f_name,
            a_nr_act,
            a_parcela,
            a_tarlaua,
            a_ha,
            a_categoria,
            a_bloc_fizic
        )
        // console.log("intersect:::::::::::::::", intersect)
        return intersect

    }

    toggleModal = () => {
        this.setState({ addModalOpen: !this.state.addModalOpen })
    }

    removeRegistruItem = ev => {
        console.log("ev", ev)
        if ( ev.tip_doc && ev.tip_doc === 'CONTRACT') {
            let children = this.state.registru.filter( elem => elem.parent === ev.id )
            this.setState({focus: {...ev, children: children}, confirmationOpen: true})
        } else {
            this.setState({focus: ev, confirmationOpen: true})
        }
         
    }

    removeItem = async ev => {
        console.log("remove actually")
        if (ev) {
            let registru = this.state.registru, result = null;
            try {
                result = await Axios({
                    method: 'POST',
                    url: '/remove',
                    data: { table: 'registru', data: { id: ev.id } }
                })
            } catch (err) { console.log("remove item from registru err:", err) }
            finally {
                console.log(result)
                registru.splice(registru.indexOf(ev), 1)
    
            }
           
        }
        this.setState({confirmationOpen: false})
    }

    filterToggler = () =>  {
        let filterState = this.state.filterVisible;
        let pageHeight = filterState ? 600 : 470
        this.setState({filterVisible: !filterState, pageHeight: pageHeight})

    }

    render() {
        // console.log("Registru!")
        // console.log("Registru;",this.state)
        const { anchorEl } = this.state;

        return (
            <div>
                {this.state.dataLoaded
                    ? (
                        <div>
                            {this.state.filterVisible ? <Filter {...this.state} /> : null}

                            <Tooltip title={!this.state.visible ? "ARATĂ FILTRELE" : "ASCUNDE FILTRELE"}>
                                <Grid container direction="row" justify="space-around" onClick={this.filterToggler }>
                                    {this.state.filterVisible ? <FaChevronUp /> : <FaChevronDown />}
                                </Grid>
                            </Tooltip>
                            <Grid container direction="row" justify="space-between" alignItems="stretch">
                                <div></div>
                                <Fab
                                    size="small"
                                    aria-owns={anchorEl ? 'links-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={ev => this.setState({ anchorEl: ev.currentTarget })}
                                    variant="round" mini="true"
                                    color="primary"
                                    style={{ marginTop: "-30px", marginBotom: '-50px', marginRight: "10px", zIndex: 9999 }}
                                // onClick = { ()=> this.props.history.push("/contract/nou") }
                                >
                                    <FaPlus style={{ fontSize: '2em' }} />
                                </Fab>

                            </Grid>

                            <ListRenderer data={this.state.data} registru={this.state.registru} remove={this.removeRegistruItem} pageHeight = {this.state.pageHeight} />

                            <Menu
                                id="links-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({ anchorEl: undefined })}
                                style={{ zIndex: 99999 }}
                            >
                                <MenuItem onClick={() => this.props.history.push("/contract/nou")}> <FaPlusCircle style={{ fontSize: '1.2em' }} /> &nbsp;CONTRACT</MenuItem>
                                <MenuItem onClick={() => this.props.history.push("/act-aditional/nou")}><FaPlusCircle style={{ fontSize: '1.2em' }} /> &nbsp;ACT ADIȚIONAL</MenuItem>
                                <MenuItem onClick={() => this.props.history.push("/reziliere/nou")}><FaPlusCircle style={{ fontSize: '1.2em' }} /> &nbsp;REZILIERE</MenuItem>

                            </Menu>

                        </div>
                    )
                    : <div>Se încarcă datele...</div>
                }

                <ConfirmDialog 
                    open = { this.state.confirmationOpen }
                    onClose = { ev => this.removeItem(ev) }
                    title = {(<div>
                        ȘTERGERE 
                        &nbsp;
                        {this.state.focus && this.state.focus.tip_doc ? this.state.focus.tip_doc : null }
                        &nbsp;
                        {this.state.focus && this.state.focus.nr_doc ? this.state.focus.nr_doc : null }
                        &nbsp;/&nbsp;
                        {this.state.focus && this.state.focus.data_doc ? moment(this.state.focus.data_doc).format('DD.MM.YYYY') : null }
                    </div>)}
                    >
                    { this.state.focus && this.state.focus.tip_doc === 'CONTRACT'
                        ? (
                            <span>
                                    <Typography variant = "caption" color = "primary">
                                    
                                        Se va șterge {this.state.focus.tip_doc} &nbsp; {this.state.focus.nr_doc} 
                                        &nbsp; / &nbsp; {moment(this.state.focus.data_doc).format('DD.MM.YYYY')}
                                        
                                    </Typography>
                                    { this.state.focus.tip_doc === 'CONTRACT' && this.state.focus.children.length > 0  
                                        ? (
                                            <Typography variant = "caption" color = "secondary">
                                                <strong>ATENȚIE!!! </strong>
                                                <br/>
                                                Vor fi șterse, de asemenea, și următoarele documente izvorâte din contract:
                                                 <br/>
                                                     {this.state.focus.children.map( (e,i) => (
                                                         <Typography variant = "caption" color = "primary" key = {`child-${i}`}>
                                                             - {e.tip_doc + ' ' + e.nr_doc + ' / ' + e.data_doc} <br/>
                                                         </Typography>
                                                     ))}
                                            </Typography>
                                        )
                                        : null
                                    
                                    }
                            </span>
                        ) : null
                    }
                </ConfirmDialog>
                
            </div>

        )

    }
}

export default withRouter(Registru); 
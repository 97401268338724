import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import App from "./App"
import Login from './Login'

class Preloader extends React.Component {

    render() {
        // console.log(this.props)
        return (
            <BrowserRouter>
            <Switch>
               
                <Route path = "/login/:login?"  component = {Login} />
               
                <Route path = "*"  render = { () => <App/>}  />
               
            </Switch>
          </BrowserRouter>
        )
    }

}

export default Preloader;

import moment from 'moment';

const ContactItem = {
    nume: "",
    loc: "",
    str: "",
    nr: "",
    bl: "",
    sc: "",
    ap: "",
    jud: "",
    cp: "",
    tara: "",
    ci_nr: "",
    ci_seria: "",
    ci_elib: "",
    ci_data: "",
    cnp_cui: "",
    reg_com: ""
}

const AnexesItem = {
    idreg: "",
    nr_act: "",
    tarlaua: "",
    parcela: "",
    ha: "",
    bloc_fizic: "",
    categoria: "",
    vecin_n: "",
    vecin_s: "",
    vecin_e: "",
    vecin_v: ""
}

const ContractItem = {
    
    parent      : null,
    nr_doc      : 0,
    data_doc    : moment(new Date()).format('YYYY-MM-DD'), 
    tip_doc     : "CONTRACT",
    pret        : " ", 
    start       : moment(new Date()).format('YYYY-MM-DD'),
    end         : moment(moment(new Date()).add('years', 5)).format('YYYY-MM-DD'),
    parte1_parsed: [],
    parte2_parsed: [],
    anexes      : [] , 
    props: {obs: ''}
}

const ConexItem = {
    
    parent      : null,
    nr_doc      : 0,
    data_doc    : moment(new Date()).format('YYYY-MM-DD'), 
    tip_doc     : null,
    pret        : null, 
    parte1      : null,
    parte2      : null, 
    parte1_parsed: [],
    parte2_parsed: [],
    props       : {obs: ''}
}

const Interfaces =  {

        contact: ContactItem,
        anexa: AnexesItem,
        contract: ContractItem,
        conex: ConexItem

}

export default Interfaces;